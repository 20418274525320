import {ifPartlyShowSection} from '../functions'

const upElement = document.querySelector('.btn__up');

if(upElement) {
    upElement.addEventListener('click', () => {

        // if(document.querySelector('.home-page')) {
        //     scrollToPrevSection();
        //     return
        // }

        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    })
}

window.addEventListener('scroll', () => {
    if(window.scrollY > 10) {   
        if(!document.querySelector('.whats-new__content--discover')) {
            upElement.classList.add('show');
        }
    } else {
        upElement.classList.remove('show');
    }
})

const scrollToPrevSection = () => {

    if(ifPartlyShowSection('#whats-new')) {
        document.querySelector('.main').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('#our-presence')) {
        document.querySelector('#whats-new').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('#our-impact')) {
        document.querySelector('#our-presence').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('#media')) {
        document.querySelector('#our-impact').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('footer') && ifPartlyShowSection('#who-we-are')) {
        document.querySelector('.main').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('#who-we-are')) {
        document.querySelector('#media').scrollIntoView({
            behavior: 'smooth'
        });
    }

    else if(ifPartlyShowSection('footer')) {
        document.querySelector('.main').scrollIntoView({
            behavior: 'smooth'
        });
    }
}