import {isScrollIntoView} from '../functions'

const allSquares = document.querySelectorAll('.contacts-square');

document.addEventListener('DOMContentLoaded', () => animateAllSqueres())

window.addEventListener('scroll', () => animateAllSqueres())

const animateAllSqueres = () => {
    if(allSquares.length) {
        allSquares.forEach(s => {
            if(isScrollIntoView(s)) {
                setTimeout(() => {
                    s.classList.add('square--animate')
                }, 600)
            }
        })
    }
}

let nodeContactsLogo;

document.addEventListener('DOMContentLoaded', () => {

    if(!nodeContactsLogo) {
        nodeContactsLogo = document.querySelector('.logo--contacts');
    }

    if(nodeContactsLogo) {
        if(isScrollIntoView(nodeContactsLogo)) {
            try {

                setTimeout(() => {
                    nodeContactsLogo.classList.add('logo--triangle')
                }, 500)

                setTimeout(() => {
                    nodeContactsLogo.classList.add('logo--rect')
                }, 750)
            } catch(err) {
                console.error(err);
            }
            
        }

        
    }
})

window.addEventListener('scroll', () => animateAllSqueres())