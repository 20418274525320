export function counter(id, start, end, duration) {
    let obj = document.getElementById(id),
        current = start,
        range = end - start,
        increment = end > start ? 1 : -1,
        step = Math.abs(Math.floor(duration / range)),
        timer = setInterval(() => {
            current += increment;
            obj.textContent = delimiterFormat(current);
            if (current == end) {
                clearInterval(timer);
            }
        }, step);
}

export const isScrollIntoView = (element) => {
    try {
        if (element) {
            var rect = element.getBoundingClientRect();
            var html = document.documentElement;
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || html.clientHeight) &&
                rect.right <= (window.innerWidth || html.clientWidth)
            );
        }
    } catch (err) {
        console.log(err);
    }
};

export const delimiterFormat = (value) => {
    try {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    } catch (err) {
        console.error(err);
    }
};

export const ourImpactFormatItem = (
    str,
    icon,
    count,
    target,
    numClass,
    queueNum
) => {
    if (!str) {
        return;
    }

    const strArr = str.innerText.split(" ");

    let newItem = document.createElement("div");
    newItem.classList.add(`create-el--${numClass}`);

    for (let i = 0; i < strArr.length; i++) {
        if (i < queueNum) {
            newItem.append(strArr[i] + " ");
        }
    }

    if (numClass == 7 && queueNum == 0) {
        icon.classList.add("absolute-icon");
    }

    newItem.append(count);
    newItem.append(icon);

    for (let i = queueNum; i < strArr.length; i++) {
        newItem.append(strArr[i] + " ");
    }

    if (!target) {
        return;
    }

    target.innerHTML = "";
    target.appendChild(newItem);
};

export function nFormatter(num, digits) {
    const lookup = [
        { value: 1, symbol: "" },
        { value: 1e3, symbol: "k" },
        { value: 1e6, symbol: "" },
        { value: 1e9, symbol: "G" },
        { value: 1e12, symbol: "T" },
        { value: 1e15, symbol: "P" },
        { value: 1e18, symbol: "E" },
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup
        .slice()
        .reverse()
        .find(function (item) {
            return num >= item.value;
        });
    return item
        ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol
        : "0";
}

export function getUrlParams(url_string, param) {
    if (!url_string || !param) {
        return;
    }

    const url = new URL(url_string);

    const value = url.searchParams.get(param);

    return value;
}

export function setUrlParams(key, value) {
    key = encodeURIComponent(key);
    value = encodeURIComponent(value);

    var kvp = document.location.search.substr(1).split("&");
    let i = 0;

    for (; i < kvp.length; i++) {
        if (kvp[i].startsWith(key + "=")) {
            let pair = kvp[i].split("=");
            pair[1] = value;
            kvp[i] = pair.join("=");
            break;
        }
    }

    if (i >= kvp.length) {
        kvp[kvp.length] = [key, value].join("=");
    }

    // can return this or...
    let params = kvp.join("&");

    // reload page with new params
    document.location.search = params;
}

export function insertAfter(newNode, referenceNode) {
    referenceNode.parentNode.insertBefore(newNode, referenceNode.nextSibling);
}

export const changeCounterSlider = (
    slider,
    selector,
    current = 1,
    count = 1
) => {
    selector.innerHTML = `${current}<span class="delimetr">/</span>${count}`;
};

export const ifPartlyShowSection = (id) => {
    const element = document.querySelector(id);

    if (!element) {
        return;
    }

    const position = element.getBoundingClientRect();
    return position.top < window.innerHeight && position.bottom >= 0;
};
