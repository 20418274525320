const { head } = require("lodash");
const SmoothScroll = require("smooth-scroll");
import { ifPartlyShowSection } from "../functions";
require("./search");
require("./menu");

const header = document.querySelector(".header");
const banner = document.querySelector(".banner-left");

try {
    window.addEventListener("scroll", function () {
        if (window.scrollY > 30) {
            header.classList.add("header--scroll");
            if (banner) {
                banner.classList.add("banner-left--scroll");
            }
        } else {
            header.classList.remove("header--scroll");
            if (banner) {
                banner.classList.remove("banner-left--scroll");
            }
        }
    });
} catch (err) {
    console.error(err);
}

// Scroll

try {
    const scroll = new SmoothScroll('a[href*="#"]', {
        offset: window.innerWidth < 576 ? 80 : -20,
    });

    document.addEventListener(
        "scrollStart",
        () => {
            if (
                document
                    .querySelector(".mobile-menu")
                    .classList.contains("mobile-menu--open")
            ) {
                document
                    .querySelector(".mobile-menu")
                    .classList.remove("mobile-menu--open");
            }
            if (
                document
                    .querySelector(".search")
                    .classList.contains("search--open")
            ) {
                document
                    .querySelector(".search")
                    .classList.remove("search--open");
            }
            if (
                document
                    .querySelector(".btn__header--search")
                    .classList.contains("btn__header--search-open")
            ) {
                document
                    .querySelector(".btn__header--search")
                    .classList.remove("btn__header--search-open");
                document
                    .querySelector(".search")
                    .classList.remove("search--open");
                document.querySelector(".header__menu").style = "";
                document.querySelector(".btn__header--u-learn").style = "";
                document.querySelector(".btn__header--lang").style = "";
                document.querySelector(".btn__header--toggle").style = "";
                setTimeout(() => {
                    document.querySelector("html").style.overflowY = "auto";
                    document.querySelector("body").style.marginRight = "0";
                }, 500);
            }
            if (
                document
                    .querySelector(".btn__header--toggle")
                    .classList.contains("menu--open")
            ) {
                document
                    .querySelector(".mobile-menu")
                    .classList.remove("mobile-menu--open");
                document
                    .querySelector(".btn__header--toggle")
                    .classList.remove("menu--open");
                document
                    .querySelector(".header")
                    .classList.remove("header--menu-open");
            }
            if (header.classList.contains("header--menu-open")) {
                header.classList.remove("header--menu-open");
            }
        },
        false
    );
} catch (err) {
    console.error(err);
}

const allNavTopLinks = document.querySelectorAll(".header__menu-item a");

window.addEventListener("scroll", () => checkNavSection());
document.addEventListener("DOMContentLoaded", () => {
    checkNavSection();
    if (window.scrollY > 30) {
        if (!header.classList.contains("header--not-main")) {
            header.classList.add("header--not-main");
        }
    }
});

const checkNavSection = () => {
    if (allNavTopLinks.length) {
        clearAllHeaderLinks();

        if (ifPartlyShowSection("#who-we-are")) {
            allNavTopLinks.forEach((link) => {
                if (link.href.includes("#who-we-are")) {
                    link.classList.add("visible");
                }
            });
        } else if (ifPartlyShowSection("#media")) {
            allNavTopLinks.forEach((link) => {
                if (link.href.includes("#media")) {
                    link.classList.add("visible");
                }
            });
        } else if (
            ifPartlyShowSection("#our-impact") ||
            ifPartlyShowSection("#our-impact .section-title")
        ) {
            allNavTopLinks.forEach((link) => {
                if (link.href.includes("#our-impact")) {
                    link.classList.add("visible");
                }
            });
        } else if (ifPartlyShowSection("#our-presence")) {
            allNavTopLinks.forEach((link) => {
                if (link.href.includes("#our-presence")) {
                    link.classList.add("visible");
                }
            });
        } else if (ifPartlyShowSection("#whats-new")) {
            allNavTopLinks.forEach((link) => {
                if (link.href.includes("#whats-new")) {
                    link.classList.add("visible");
                }
            });
        }
    }
};

const clearAllHeaderLinks = () => {
    if (allNavTopLinks.length) {
        allNavTopLinks.forEach((link) => {
            if (link.classList.contains("visible")) {
                link.classList.remove("visible");
            }
        });
    }
};
