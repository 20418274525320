import { counter, delimiterFormat, nFormatter } from "../functions";

const preloader = document.querySelector(".preloader-3");
const svgLoader = document.querySelector(".svg-preloader");
const main = document.querySelector(".main");

// counters

const countMainFirst = document.getElementById("count-1");
const countMainSecond = document.getElementById("count-2");
const countMainThird = document.getElementById("count-3");
const countMainFourth = document.getElementById("count-4");
let c;

document.addEventListener("DOMContentLoaded", () => {
    if (
        !document
            .querySelector("header")
            .classList.contains("header--not-main") ||
        !document.querySelector("html").classList.contains("wcag")
    ) {
        svgLoader.classList.add("svg-preloader--start");

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--square");
        }, 250);
        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--triangle");
        }, 500);

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--rect");
        }, 750);

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--scale");
        }, 1000);

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--titles");
        }, 1500);

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--titles-with");
        }, 2000);

        setTimeout(() => {
            svgLoader.classList.add("svg-preloader--position");
        }, 3000);

        setTimeout(() => {
            document
                .querySelector(".main-map")
                .classList.add("main-map--enter");
        }, 4000);

        setTimeout(() => {
            document.querySelector(".main-map").classList.add("main-map--fill");
        }, 4250);

        setTimeout(() => {
            main.classList.add("main--animate");
        }, 4500);

        setTimeout(() => {
            document.querySelector("html").classList.add("page-loaded");
            document.querySelector("body").style.marginRight = "0";
        }, 5000);

        setTimeout(() => {
            document.querySelector(".header").classList.add("header--enter");
        }, 5000);

        setTimeout(() => {
            document
                .querySelector(".main-counter--1")
                .classList.add("main-counter--enter");
            if (countMainFirst) {
                c = Number(countMainFirst.innerHTML.replace(/\s/g, ""));
                counter("count-1", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
        }, 5250);

        setTimeout(() => {
            document
                .querySelector(".main-counter--2")
                .classList.add("main-counter--enter");
            if (countMainSecond) {
                c = Number(countMainSecond.innerHTML.replace(/\s/g, ""));
                counter("count-2", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
        }, 5500);

        setTimeout(() => {
            document
                .querySelector(".main-counter--3")
                .classList.add("main-counter--enter");
            if (countMainThird) {
                c = Number(countMainThird.innerHTML.replace(/\s/g, ""));
                // if(c % 1 === 0) {
                //     counter('count-3', c - 100 < 0 ? 0 : c - 100, c, 1000)
                // }
                if (Number.isInteger(c)) {
                    counter(
                        "count-3",
                        c / 1000000 - 50 < 0 ? 0 : c / 1000000 - 50,
                        c / 1000000,
                        1250
                    );
                }
                //countMainThird.innerHTML = nFormatter(c,2).replace('.', ',').toString();
            }
        }, 5750);

        setTimeout(() => {
            if (document.querySelector(".main-counter--4")) {
                document
                    .querySelector(".main-counter--4")
                    .classList.add("main-counter--enter");
            }
            if (countMainFourth) {
                c = Number(countMainFourth.innerHTML.replace(/\s/g, ""));
                counter("count-4", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
        }, 6000);

        setTimeout(() => {
            if (document.querySelector(".banner-left")) {
                document
                    .querySelector(".banner-left")
                    .classList.add("banner-left--loaded");
            }
        }, 6500);
    } else {
        document.querySelector("html").classList.add("page-loaded");
        document.querySelector("body").style.marginRight = "0";

        setTimeout(() => {
            if (main) {
                main.classList.add("main--animate");
            }
        }, 100);

        setTimeout(() => {
            if (document.querySelector(".main-counter--1")) {
                document
                    .querySelector(".main-counter--1")
                    .classList.add("main-counter--enter");
            }

            if (countMainFirst) {
                c = Number(countMainFirst.innerHTML.replace(/\s/g, ""));
                counter("count-1", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
            //counter("count-1", Number(countMainFirst.innerHTML) - 100, Number(countMainFirst.innerHTML), 1500);
        }, 500);

        setTimeout(() => {
            if (document.querySelector(".main-counter--2")) {
                document
                    .querySelector(".main-counter--2")
                    .classList.add("main-counter--enter");
            }
            if (countMainSecond) {
                c = Number(countMainSecond.innerHTML.replace(/\s/g, ""));
                counter("count-2", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
        }, 750);

        setTimeout(() => {
            if (document.querySelector(".main-counter--3")) {
                document
                    .querySelector(".main-counter--3")
                    .classList.add("main-counter--enter");
            }
            if (countMainThird) {
                c = Number(countMainThird.innerHTML.replace(/\s/g, ""));
                // if(c % 1 === 0) {
                //     counter('count-3', c - 100 < 0 ? 0 : c - 100, c, 1000)
                // }
                if (Number.isInteger(c)) {
                    counter(
                        "count-3",
                        c / 1000000 - 50 < 0 ? 0 : c / 1000000 - 50,
                        c / 1000000,
                        1250
                    );
                }
                //countMainThird.innerHTML = nFormatter(c,2).replace('.', ',').toString();
            }
        }, 1000);

        setTimeout(() => {
            if (document.querySelector(".main-counter--4")) {
                document
                    .querySelector(".main-counter--4")
                    .classList.add("main-counter--enter");
            }
            if (countMainFourth) {
                c = Number(countMainFourth.innerHTML.replace(/\s/g, ""));
                counter("count-4", c - 100 < 0 ? 0 : c - 100, c, 1000);
            }
        }, 1250);

        setTimeout(() => {
            if (document.querySelector(".banner-left")) {
                document
                    .querySelector(".banner-left")
                    .classList.add("banner-left--loaded");
            }
        }, 1500);
    }
});
