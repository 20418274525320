/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require("./bootstrap");
window.Vue = require("vue");

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component("search-index", require("./components/search/Index.vue").default);
Vue.component("news-index", require("./components/news/Index.vue").default);
Vue.component("team-index", require("./components/team/Index.vue").default);
Vue.component("event-index", require("./components/event/Index.vue").default);
Vue.component("media-index", require("./components/media/Index.vue").default);
Vue.component("icon", require("./components/Icon/Index.vue").default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: "#app",
});

require("./parts/header");
require("./parts/up");
require("./parts/preloader");
require("./parts/home");
require("./parts/news");
require("./parts/single");
require("./parts/story");
require("./parts/media");
require("./parts/events");
require("./parts/contacts");
require("./parts/modal");
require("./parts/mission");
require("./parts/donors");
require("./parts/team");
require("./parts/cookies");
