const mobileMenu = document.querySelector('.mobile-menu');
const toggleMenu = document.querySelector('.btn__header--toggle');

if(mobileMenu) {
    try {
        toggleMenu.addEventListener('click', () => {
            if(!mobileMenu.classList.contains('mobile-menu--open')) {
                mobileMenu.classList.add('mobile-menu--open');
                toggleMenu.classList.add('menu--open');
                document.querySelector('.header').classList.add('header--menu-open');
            } else {
                mobileMenu.classList.remove('mobile-menu--open');
                toggleMenu.classList.remove('menu--open');
                document.querySelector('.header').classList.remove('header--menu-open');
            }
            
        })
    } catch(err) {
        console.error(err);
    }
}