// import {getUrlParams, setUrlParams} from '../functions'

// let currentTab = 'videos',
//     count = 9,
//     mediaItems = null;

document.addEventListener('DOMContentLoaded', () => {
    const btnShowMoreTags = document.querySelector('.btn__single-more--media');
    const btnLessMoreTags = document.querySelector('.btn__single-less');
    const tagsListSingle = document.querySelector('.media-page__tags-list');

    const mediaMenuBtns = document.querySelectorAll('.btn__media-menu');
    const mediaMenuTabs = document.querySelectorAll('.media__tab');

    const mediaMenuSvg = document.querySelector('.media__menu-svg');

    const mediaFilter = document.querySelector('.media__filter');
    const btnToggleMediaFilter = document.querySelector('.btn__toggle-filter--media');



    const animateMediaSvg = (button) => {
        if(!button) {
            return;
        }
        if(window.innerWidth > 576) {
            mediaMenuSvg.style = `top: ${button.offsetTop - 18}px; left: ${button.offsetLeft}px;`
        } else {
            mediaMenuSvg.style = `top: ${button.offsetTop + button.offsetHeight - 30}px; left: ${button.offsetLeft + button.offsetWidth - 30}px;`
        }

    }

    const clearAllMenuTabs = () => {
        mediaMenuBtns.forEach(b => {
            b.classList.remove('active');
        })
    }

    const clearAllMediaTabs = () => {
        mediaMenuTabs.forEach(t => {
            t.classList.remove('active');
        })
    }

    if(mediaMenuSvg) {

        if(mediaMenuBtns) {
            mediaMenuBtns.forEach(b => {
                if(b.classList.contains('active')) {
                    animateMediaSvg(b)
                }
            })
        }
    }

    if(btnShowMoreTags) {
        try {
            btnShowMoreTags.addEventListener('click', () => {
                if(tagsListSingle) {
                    tagsListSingle.classList.add('media-page__tags-list--all');
                    btnShowMoreTags.classList.add('hide');
                    btnLessMoreTags.classList.remove('hide');
                }
            })
        } catch(err) {
            console.error(err);
        }
    }

    if(btnLessMoreTags) {
        try {
            btnLessMoreTags.addEventListener('click', () => {
                if(tagsListSingle) {
                    tagsListSingle.classList.remove('media-page__tags-list--all');
                    btnShowMoreTags.classList.remove('hide');
                    btnLessMoreTags.classList.add('hide');
                }
            })
        } catch(err) {
            console.error(err);
        }
    }

    if(mediaMenuBtns) {
        mediaMenuBtns.forEach(btn => {
            btn.addEventListener('click', () => {
                clearAllMenuTabs();
                btn.classList.add('active');
                animateMediaSvg(btn);
            })
        })
    }

    if(btnToggleMediaFilter) {
        btnToggleMediaFilter.addEventListener('click', () => {
            if(mediaFilter) {
                if(mediaFilter.classList.contains('media__filter--open')) {
                    mediaFilter.classList.remove('media__filter--open');
                    btnToggleMediaFilter.classList.remove('btn__toggle-filter--show')
                } else {
                    mediaFilter.classList.add('media__filter--open')
                    btnToggleMediaFilter.classList.add('btn__toggle-filter--show')
                }
            }
        })
    }

})


// HTTP

// import MediaService from '../services/media';

// if(document.querySelector('.media-page')) {

//     let allItems = null;

//     const getAllData = async () => {
//         await MediaService.getAllData().then(res => {
//             allItems = res.data;
//         }).catch(err => console.error(err));
//     }

//     document.addEventListener('DOMContentLoaded', () => {

//         const tab = getUrlParams(window.location.href, 'tab') ? getUrlParams(window.location.href, 'tab') : 'videos';

//         getDataByTab(tab);

//         clearAllMediaTabs();
//         clearAllMenuTabs();

//         mediaMenuBtns.forEach(btn => {
//             if(btn.dataset.tab === tab) {
//                 btn.classList.add('active')
//             }
//         })

//         mediaMenuTabs.forEach(t => {
//             if(t.dataset.tab === tab) {
//                 t.classList.add('active')
//             }
//         })

//     })

//     const getDataByTab = async (tab) => {

//         await MediaService.getDataByTab(tab).then(res => {

//             currentTab = tab;
//             mediaItems = res.data;

//             if(tab === 'videos') {
//                 mediaMenuTabs.forEach(contentTab => {

//                     if(contentTab.dataset.tab === tab) {
//                         const divWrap = document.createElement('div');
//                         divWrap.classList.add('media__tab-wrapper');

//                         res.data.forEach(item => {

//                             const {id, title, short, preview, file, embed, tab, tags} = item;

//                             let newItem = document.createElement('div');
//                             newItem.classList.add('media__item');

//                             if(preview) {
//                                 newItem.insertAdjacentHTML( 'beforeend', `
//                                     <div class="media__item-video">
//                                         <div class="video">
//                                             <video class="media-video" src="${preview}" data-video="${id}"></video>
//                                             <button type="button" class="control play-video" data-video="${id}">
//                                                 <div class="circle">
//                                                     <div class="circle__inner">
//                                                         <div class="triangle"></div>
//                                                     </div>
//                                                 </div>
//                                             </button>
//                                         </div>
//                                     </div>
//                                 `)
//                             } else if(embed) {
//                                 newItem.insertAdjacentHTML( 'beforeend', `
//                                     <div class="media__item-video">
//                                         <div class="video">
//                                             ${embed}
//                                         </div>
//                                     </div>
//                                 `)
//                             } else {
//                                 return
//                             }

//                             if(title) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-title">${title}</div>
//                                 `)
//                             }

//                             if(short) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-text">${short}</div>
//                                 `)
//                             }

//                             if(file) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-download">
//                                         <a href="${file}" target="_blank" class="btn btn__media-download">
//                                             <div class="icon">
//                                                 <svg id="download" x="0px" y="0px" viewBox="0 0 15 21" style="enable-background:new 0 0 15 21;">
//                                                     <path d="M14.68,12.02l-6.07,6.11v0.65h4.47c0.55,0,1,0.45,1,1V20c0,0.55-0.45,1-1,1H1.92c-0.55,0-1-0.45-1-1v-0.22
//                                                         c0-0.55,0.45-1,1-1h4.47v-0.65l-6.07-6.11c-0.43-0.43-0.43-1.13,0-1.56c0.43-0.43,1.12-0.43,1.55,0l4.52,4.55V1c0-0.55,0.45-1,1-1
//                                                         h0.22c0.55,0,1,0.45,1,1v14l4.51-4.54c0.43-0.43,1.12-0.43,1.55,0C15.11,10.89,15.11,11.59,14.68,12.02z"/>
//                                                 </svg>
//                                             </div>
//                                             Download
//                                         </a>
//                                     </div>
//                                 `)
//                             }

//                             if(tags.length) {

//                                 let tagsElement = document.createElement('div')
//                                 tagsElement.classList.add('media__item-tags');

//                                 tags.forEach(tag => {
//                                     tagsElement.insertAdjacentHTML('beforeend', `
//                                         <a href="${window.location.origin}${window.location.pathname}?tag=${tag.tag}" class="tag">${tag.tag}</a>
//                                     `)
//                                 })
//                             }

//                             divWrap.appendChild(newItem)
//                         })
//                         contentTab.innerHTML = '';
//                         contentTab.appendChild(divWrap);

//                     }
//                 })
//             }

//             if(tab === 'reports' || tab === 'factsheets') {
//                 mediaMenuTabs.forEach(contentTab => {

//                     contentTab.innerHTML = '';

//                     if(contentTab.dataset.tab === tab) {
//                         const divWrap = document.createElement('div');
//                         divWrap.classList.add('media__tab-wrapper');

//                         res.data.forEach(item => {

//                             const {id, title, short, preview, file, embed, tab, tags} = item;

//                             let newItem = document.createElement('div');
//                             newItem.classList.add('media__item');
//                             newItem.classList.add('media__item--not-media');

//                             if(title) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-title">${title}</div>
//                                 `)
//                             }

//                             if(short) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-text">${short}</div>
//                                 `)
//                             }

//                             if(file) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-download">
//                                         <a href="${file}" target="_blank" class="btn btn__media-download">
//                                             <div class="icon">
//                                                 <svg id="download" x="0px" y="0px" viewBox="0 0 15 21" style="enable-background:new 0 0 15 21;">
//                                                     <path d="M14.68,12.02l-6.07,6.11v0.65h4.47c0.55,0,1,0.45,1,1V20c0,0.55-0.45,1-1,1H1.92c-0.55,0-1-0.45-1-1v-0.22
//                                                         c0-0.55,0.45-1,1-1h4.47v-0.65l-6.07-6.11c-0.43-0.43-0.43-1.13,0-1.56c0.43-0.43,1.12-0.43,1.55,0l4.52,4.55V1c0-0.55,0.45-1,1-1
//                                                         h0.22c0.55,0,1,0.45,1,1v14l4.51-4.54c0.43-0.43,1.12-0.43,1.55,0C15.11,10.89,15.11,11.59,14.68,12.02z"/>
//                                                 </svg>
//                                             </div>
//                                             Download
//                                         </a>
//                                     </div>
//                                 `)
//                             }

//                             if(tags.length) {

//                                 let tagsElement = document.createElement('div')
//                                 tagsElement.classList.add('media__item-tags');

//                                 tags.forEach(tag => {
//                                     tagsElement.insertAdjacentHTML('beforeend', `
//                                         <a href="${window.location.origin}${window.location.pathname}?tag=${tag.tag}" class="tag">${tag.tag}</a>
//                                     `)
//                                 })
//                             }

//                             contentTab.appendChild(newItem)
//                         })

//                     }
//                 })
//             }

//             if(tab === 'books' || tab === 'infographics' || tab === 'brands') {
//                 mediaMenuTabs.forEach(contentTab => {

//                     if(contentTab.dataset.tab === tab) {
//                         const divWrap = document.createElement('div');
//                         divWrap.classList.add('media__tab-wrapper');

//                         res.data.forEach(item => {

//                             const {id, title, short, preview, file, embed, tab, tags} = item;

//                             let newItem = document.createElement('div');
//                             newItem.classList.add('media__item');

//                             if(preview) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-image">
//                                         <img src="${preview}" />
//                                     </div>
//                                 `)
//                             }

//                             if(title) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-title">${title}</div>
//                                 `)
//                             }

//                             if(short) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-text">${short}</div>
//                                 `)
//                             }

//                             if(file) {
//                                 newItem.insertAdjacentHTML('beforeend', `
//                                     <div class="media__item-download">
//                                         <a href="${file}" target="_blank" class="btn btn__media-download">
//                                             <div class="icon">
//                                                 <svg id="download" x="0px" y="0px" viewBox="0 0 15 21" style="enable-background:new 0 0 15 21;">
//                                                     <path d="M14.68,12.02l-6.07,6.11v0.65h4.47c0.55,0,1,0.45,1,1V20c0,0.55-0.45,1-1,1H1.92c-0.55,0-1-0.45-1-1v-0.22
//                                                         c0-0.55,0.45-1,1-1h4.47v-0.65l-6.07-6.11c-0.43-0.43-0.43-1.13,0-1.56c0.43-0.43,1.12-0.43,1.55,0l4.52,4.55V1c0-0.55,0.45-1,1-1
//                                                         h0.22c0.55,0,1,0.45,1,1v14l4.51-4.54c0.43-0.43,1.12-0.43,1.55,0C15.11,10.89,15.11,11.59,14.68,12.02z"/>
//                                                 </svg>
//                                             </div>
//                                             Download
//                                         </a>
//                                     </div>
//                                 `)
//                             }

//                             if(tags.length) {

//                                 let tagsElement = document.createElement('div')
//                                 tagsElement.classList.add('media__item-tags');

//                                 tags.forEach(tag => {
//                                     tagsElement.insertAdjacentHTML('beforeend', `
//                                         <a href="${window.location.origin}${window.location.pathname}?tag=${tag.tag}" class="tag">${tag.tag}</a>
//                                     `)
//                                 })
//                             }

//                             divWrap.appendChild(newItem)
//                         })
//                         contentTab.innerHTML = '';
//                         contentTab.appendChild(divWrap);

//                     }
//                 })
//             }
//         })
//     }

//     if(mediaMenuBtns) {
//         mediaMenuBtns.forEach(btn => {
//             btn.addEventListener('click', () => {
//                 getDataByTab(btn.dataset.tab);
//                 setUrlParams('tab', btn.dataset.tab);
//             });
//         })
//     }
// }
