<template>
    <main class="media-page">
        <div class="container">
            <div class="media__top">
                <div
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="500"
                    class="breadcrumbs"
                >
                    <a :href="`/${lang}`">{{ transl.home.title }}</a>
                    <span class="line">—</span>
                    <a :href="`/${lang}/#media`">{{ transl.media.title }}</a>
                    <span class="line">—</span>
                    <span>{{ tabs[filter.tab] }}</span>
                </div>
                <div
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="500"
                    class="section-title section-title--media-page"
                >
                    {{ transl.media.title }}
                </div>

                <button
                    class="btn btn__toggle-filter btn__toggle-filter--media"
                >
                    {{ transl.media.search_in }}
                </button>

                <div
                    data-aos="fade-up"
                    data-aos-delay="1000"
                    data-aos-duration="500"
                    class="news__filter media__filter"
                >
                    <form class="media__form">
                        <input
                            :placeholder="`${transl.media.field_search_in} ${
                                tabs[filter.tab]
                            }`"
                            v-model="filter.search"
                            class="input input__media"
                            type="text"
                        />
                        <button @click.prevent="apply" class="btn btn__apply">
                            {{ transl.media.search }}
                        </button>
                        <button @click.prevent="cancel" class="btn btn__cancel">
                            {{ transl.media.cancel }}
                        </button>
                    </form>
                </div>
            </div>
            <div
                class="media__menu"
                data-aos="fade-up"
                data-aos-delay="1000"
                data-aos-duration="500"
            >
                <button
                    @click="selectTab('analytics')"
                    data-tab="analytics"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'analytics' ? 'active' : ''
                    }`"
                >
                    {{ tabs.analytics }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["analytics"] }}</span
                    >
                </button>
                <button
                    @click="selectTab('manuals')"
                    data-tab="manuals"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'manuals' ? 'active' : ''
                    }`"
                >
                    {{ tabs.manuals }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["manuals"] }}</span
                    >
                </button>
                <button
                    @click="selectTab('illustrations')"
                    data-tab="illustrations"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'illustrations' ? 'active' : ''
                    }`"
                >
                    {{ tabs.illustrations }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["illustrations"] }}</span
                    >
                </button>
                <button
                    @click="selectTab('videos')"
                    data-tab="videos"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'videos' ? 'active' : ''
                    }`"
                >
                    {{ tabs.videos }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["videos"] }}</span
                    >
                </button>
                <button
                    @click="selectTab('brands')"
                    data-tab="brands"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'brands' ? 'active' : ''
                    }`"
                >
                    {{ tabs.brands }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["brands"] }}</span
                    >
                </button>
                <button
                    @click="selectTab('platforms')"
                    data-tab="platforms"
                    :class="`btn btn__media-menu ${
                        filter.tab === 'platforms' ? 'active' : ''
                    }`"
                >
                    {{ tabs.platforms }}
                    <span
                        v-if="
                            (filter.search && filter.eventSearch) || filter.tag
                        "
                        class="count"
                        >{{ counters["platforms"] }}</span
                    >
                </button>
                <div class="media__menu-svg">
                    <icon name="media-menu" />
                </div>
            </div>
            <div
                class="media-page__content"
                data-aos="fade-up"
                data-aos-delay="1000"
                data-aos-duration="500"
            >
                <div class="media__tab" v-if="filter.tab === 'videos'">
                    <div class="media__tab-wrapper">
                        <div v-if="isLoading" class="media__loader">
                            <img src="/images/loader.svg" alt="Завантаження" />
                        </div>
                        <div
                            v-else
                            v-for="(media, index) of list.mediafiles"
                            :key="index"
                            class="media__item"
                        >
                            <div class="media__item-video">
                                <div class="video" v-if="media.preview">
                                    <video
                                        preload="metadata"
                                        class="media-video"
                                        :src="`${media.preview}#t=0.001`"
                                        :data-video="`${media.id}`"
                                    ></video>
                                    <button
                                        type="button"
                                        class="control play-video"
                                        :data-video="`${media.id}`"
                                    >
                                        <div class="circle">
                                            <div class="circle__inner">
                                                <div class="triangle"></div>
                                            </div>
                                        </div>
                                    </button>
                                </div>
                                <div
                                    class="video video-y"
                                    v-if="media.embed && !media.preview"
                                    v-html="media.embed"
                                ></div>
                            </div>
                            <div class="media__item-title">
                                {{ media.title }}
                            </div>
                            <div class="media__item-text">
                                {{ media.short }}
                            </div>
                            <div class="media__item-download" v-if="media.file">
                                <a
                                    :href="`${media.file}`"
                                    target="_blank"
                                    class="btn btn__media-download"
                                >
                                    <div class="icon">
                                        <icon name="download-media" />
                                    </div>
                                    {{ transl.media.open }}
                                </a>
                            </div>
                            <div class="media__item-tags">
                                <div
                                    v-for="(tag, index) of media.tags"
                                    :key="index"
                                    class="tag"
                                >
                                    #{{ tag.tag }}
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="!isLoading && list.mediafiles.length === 0"
                            class="media__no-data"
                        >
                            <icon class="no-data-svg" name="no-data" />
                            {{ transl.media.empty }}
                        </div>
                    </div>
                </div>
                <div
                    class="media__tab"
                    v-if="
                        filter.tab === 'illustrations' ||
                        filter.tab === 'brands'
                    "
                >
                    <div class="media__tab-wrapper">
                        <div v-if="isLoading" class="media__loader">
                            <img src="/images/loader.svg" alt="Завантаження" />
                        </div>
                        <div
                            v-if="!isLoading && list.mediafiles.length === 0"
                            class="media__no-data"
                        >
                            <icon class="no-data-svg" name="no-data" />
                            {{ transl.media.empty }}
                        </div>
                        <div
                            v-else
                            v-for="(media, index) of list.mediafiles"
                            :key="index"
                            class="media__item"
                        >
                            <div class="media__item-image">
                                <img
                                    :src="`${media.preview}`"
                                    :alt="media.title"
                                />
                            </div>
                            <div class="media__item-title">
                                {{ media.title }}
                            </div>
                            <div class="media__item-text">
                                {{ media.short }}
                            </div>
                            <div class="media__item-download" v-if="media.file">
                                <a
                                    :href="`${media.file}`"
                                    target="_blank"
                                    class="btn btn__media-download"
                                >
                                    <div class="icon">
                                        <icon name="download-media" />
                                    </div>
                                    {{ transl.media.open }}
                                </a>
                            </div>
                            <div class="media__item-tags">
                                <div
                                    v-for="(tag, index) of media.tags"
                                    :key="index"
                                    class="tag"
                                >
                                    #{{ tag.tag }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    class="media__tab"
                    v-if="
                        filter.tab === 'analytics' || filter.tab === 'manuals'
                    "
                >
                    <div v-if="isLoading" class="media__loader">
                        <img src="/images/loader.svg" alt="Завантаження" />
                    </div>
                    <div
                        v-if="!isLoading && list.mediafiles.length === 0"
                        class="media__no-data"
                    >
                        <icon class="no-data-svg" name="no-data" />
                        {{ transl.media.empty }}
                    </div>
                    <div
                        v-else
                        v-for="(media, index) of list.mediafiles"
                        :key="index"
                        class="media__item media__item--not-media"
                    >
                        <div class="media__item-title">{{ media.title }}</div>
                        <div v-if="media.preview" class="media__item-image">
                            <img :src="media.preview" :alt="media.title" />
                        </div>
                        <div class="media__item-text">{{ media.short }}</div>
                        <div class="media__item-download" v-if="media.file">
                            <a
                                download
                                :href="`${media.file}`"
                                target="_blank"
                                class="btn btn__media-download"
                            >
                                <div class="icon">
                                    <icon name="download-media" />
                                </div>
                                {{ transl.media.download }}
                            </a>
                        </div>
                        <div class="media__item-tags">
                            <div
                                v-for="(tag, index) of media.tags"
                                :key="index"
                                class="tag"
                            >
                                #{{ tag.tag }}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="media__tab" v-if="filter.tab === 'platforms'">
                    <div class="media__tab-wrapper">
                        <div v-if="isLoading" class="media__loader">
                            <img src="/images/loader.svg" alt="Завантаження" />
                        </div>
                        <div
                            v-if="!isLoading && list.mediafiles.length === 0"
                            class="media__no-data"
                        >
                            <icon class="no-data-svg" name="no-data" />
                            {{ transl.media.empty }}
                        </div>
                        <div
                            v-else
                            v-for="(media, index) of list.mediafiles"
                            :key="index"
                            class="media__item"
                        >
                            <div class="media__item-platform--preview">
                                <div v-if="media.file" class="image">
                                    <img
                                        :src="media.file"
                                        :alt="media.href.title"
                                    />
                                </div>
                                <div v-else class="image">
                                    <img
                                        :src="media.href.cover"
                                        :alt="media.href.title"
                                    />
                                </div>
                                <div class="title bold">
                                    {{ media.href.title }}
                                </div>
                                <div class="text">
                                    {{ media.href.description }}
                                </div>
                            </div>
                            <div class="media__item-platform--descr">
                                <div class="media__item-title">
                                    {{ media.title }}
                                </div>
                                <div class="media__item-text">
                                    {{ media.short }}
                                </div>
                                <div
                                    class="media__item-download"
                                    v-if="media.href.link"
                                >
                                    <a
                                        download
                                        :href="`${media.href.link}`"
                                        target="_blank"
                                        class="btn btn__media-download btn__media-download--platform"
                                    >
                                        {{ transl.media.open }}
                                    </a>
                                </div>
                                <div class="media__item-tags">
                                    <div
                                        v-for="(tag, index) of media.tags"
                                        :key="index"
                                        class="tag"
                                    >
                                        #{{ tag.tag }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-show="max_pagination_count > count" class="media-page__more">
                <button @click="showMore" class="btn btn__media-more">
                    {{ transl.show_more }}
                </button>
            </div>

            <div class="media-page__tags">
                <hr class="media-page__hr" />
                <div class="container">
                    <div class="media-page__tags-wrapper">
                        <div class="media-page__tags-list">
                            <a
                                style="cursor: pointer"
                                v-for="(tag, index) of list.tags"
                                :key="index"
                                @click="selectTag(tag.id)"
                                :class="`media-page__tag ${
                                    tag.id == filter.tag ? 'active' : ''
                                }`"
                                >#{{ tag.tag }}</a
                            >
                        </div>
                    </div>
                    <div class="media-page__more--tags">
                        <button
                            class="btn btn__single-more btn__single-more--media"
                        >
                            {{ transl.more_tags }}
                            <div class="dots">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </button>
                        <button
                            class="btn btn__single-more btn__single-more--media btn__single-less hide"
                        >
                            {{ transl.less_tags }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { getUrlParams } from "../../functions";
import SmoothScroll from "smooth-scroll";

export default {
    name: "Index",
    props: ["request_tag", "lang", "page", "transl"],
    data() {
        return {
            filter: {
                search: "",
                eventSearch: false,
                tag: false,
                tab: getUrlParams(window.location.href, "tab")
                    ? getUrlParams(window.location.href, "tab")
                    : "analytics",
            },

            list: {
                mediafiles: [],
                tags: [],
            },

            tabs: {
                analytics: this.transl.media.tabs.analytics,
                manuals: this.transl.media.tabs.manuals,
                illustrations: this.transl.media.tabs.illustrations,
                videos: this.transl.media.tabs.videos,
                brands: this.transl.media.tabs.brands,
                platforms: this.transl.media.tabs.platforms,
            },

            counters: [],
            scrollObject: new SmoothScroll(),
            isLoading: true,
            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
        };
    },

    mounted() {
        this.getMediaList(this.filter);

        this.getTagsList();
    },

    methods: {
        async getMediaList(filters) {
            this.list.mediafiles = [];

            this.isLoading = true;

            await axios
                .post(`/api/${this.lang}/media/list`, {
                    filters,
                })
                .then((r) => {
                    this.list.mediafiles = r.data.data;
                    this.pagination_count = this.count = r.data.count;
                    this.max_pagination_count = r.data.max_pagination_count;
                    this.counters = r.data.counters;
                })
                .finally(() => {
                    this.isLoading = false;
                });

            if (filters.tab === "videos") {
                this.addEventsOnVideos();
            }
        },

        getTagsList() {
            axios
                .post("/api/" + this.lang + "/tag/list", {
                    relation: "media",
                })
                .then((r) => {
                    this.list.tags = r.data;
                });
        },

        addEventsOnVideos() {
            const videos = document.querySelectorAll("video");
            const playBtns = document.querySelectorAll(".play-video");
            try {
                if (playBtns) {
                    playBtns.forEach((playBtn) => {
                        playBtn.addEventListener("click", () => {
                            if (videos) {
                                videos.forEach(async (video) => {
                                    if (
                                        video.dataset.video ===
                                        playBtn.dataset.video
                                    ) {
                                        await video.play();
                                        video.controls = true;
                                        playBtn.classList.add("hidden");
                                    }
                                });
                            }
                        });
                    });
                }
            } catch (err) {
                console.error(err);
            }
        },

        apply() {
            this.filter.eventSearch = true;
            this.getMediaList(this.filter);
        },

        selectTag(id) {
            if (id !== this.filter.tag) {
                this.filter.tag = id;

                let filters = {
                    tab: this.filter.tab,
                    search: this.filter.search,
                    tag: id,
                };
                this.getMediaList(filters);
            } else {
                this.filter.tag = false;

                let filters = {
                    tab: this.filter.tab,
                    search: this.filter.search,
                };
                this.getMediaList(filters);
            }

            this.scrollObject.animateScroll(0, null, {
                speed: 1000,
                easing: "easeOutCubic",
            });
        },

        selectTab(id) {
            this.filter.tab = id;

            let filters = {
                tag: this.filter.tag,
                tab: id,
                search: this.filter.search,
            };
            this.getMediaList(filters);
        },

        showMore() {
            let filters = {
                count: (this.count += this.pagination_count),
                tag: this.filter.tag,
                tab: this.filter.tab,
            };
            this.getMediaList(filters);
        },
        cancel() {
            if (this.filter.search !== "" || this.filter.tag !== false) {
                this.filter.search = "";
                this.filter.tag = false;
                this.filter.eventSearch = false;
                this.getMediaList(this.filter);
            }
        },
    },
};
</script>
