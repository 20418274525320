document.addEventListener('DOMContentLoaded', () => {
    const cookieModalCloseBtn = document.querySelector('.btn__close-modal--cookies');
    const cookieModal = document.querySelector('.cookies-modal');

    if(cookieModal) {
        cookieModalCloseBtn.addEventListener('click', () => {
            if(!cookieModal.classList.contains('hide')) {
                cookieModal.classList.add('hide')
            }
        })
    }
})

