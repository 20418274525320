const swiper = require('swiper');
const {Pagination} = require('swiper');
import {isScrollIntoView, changeCounterSlider} from '../functions'

const eventsPageSlider = document.querySelector('.events__activities-slider');

let initEventsSlider;

if(eventsPageSlider) {
    try {

        initEventsSlider = new swiper.Swiper(eventsPageSlider, {
            modules: [Pagination],
            speed: 500,
            spaceBetween: 35,
            slidesPerView: 3,
            lazy: true,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<span class="${className}">${index + 1}</span>`
                }
            },
            navigation: {
                prevEl: '.events__activities-button-prev',
                nextEl: '.events__activities-button-next',
            },
            breakpoints: {
                320: {
                    slidesPerView: 1
                },
                768: {
                    slidesPerView: 2
                },
                1280: {
                    slidesPerView: 3
                }
            }
        })

        changeCounterSlider(initEventsSlider, document.querySelector('.events__activities-slider-pagination'), 1, initEventsSlider.slides.length);

    } catch(err) {
        console.error(err);
    }

    try {
        document.querySelector('.events__activities-button-prev').addEventListener('click', () => {
            initEventsSlider.slidePrev();
            changeCounterSlider(initEventsSlider, document.querySelector('.events__activities-slider-pagination'), initEventsSlider.realIndex + 1, initEventsSlider.slides.length);
        })

        document.querySelector('.events__activities-button-next').addEventListener('click', () => {
            initEventsSlider.slideNext();
            changeCounterSlider(initEventsSlider, document.querySelector('.events__activities-slider-pagination'), initEventsSlider.realIndex + 1, initEventsSlider.slides.length);
        })
    } catch(err) {
        console.error(err);
    }
}

const allSquares = document.querySelectorAll('.events-square');

document.addEventListener('DOMContentLoaded', () => animateAllSqueres())

window.addEventListener('scroll', () => animateAllSqueres())

const animateAllSqueres = () => {
    if(allSquares.length) {
        allSquares.forEach(s => {
            if(isScrollIntoView(s)) {
                setTimeout(() => {
                    s.classList.add('square--animate')
                }, 600)
            }
        })
    }
}