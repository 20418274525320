const modalEvents = document.querySelector('.events-modal');
const closeModalEvents = document.querySelector('.btn__close-modal');
const btnsOpenModalEvents = document.querySelectorAll('.event-modal-selector');

if(modalEvents) {
    if(btnsOpenModalEvents.length) {
        btnsOpenModalEvents.forEach(btn => {
            btn.addEventListener('click', () => {
                if(modalEvents.classList.contains('modal--open')) {
                   return 
                }

                const sourceItem = btn.closest('.events__activities-item');

                const color = window.getComputedStyle(sourceItem, null).getPropertyValue('background')

                const titleItem = sourceItem.querySelector('.title').innerHTML;
                const textItem = sourceItem.querySelector('.text').innerHTML;
                const addressItem = sourceItem.querySelector('.footer span').innerHTML;

                if(titleItem, textItem, addressItem) {
                    modalEvents.querySelector('.title').innerHTML = titleItem;
                    modalEvents.querySelector('.text').innerHTML = textItem;
                    modalEvents.querySelector('.address span').innerHTML = addressItem;

                    if(color) {
                        modalEvents.querySelector('.modal').style.background = color;
                    }

                    modalEvents.classList.add('modal--open')
                }

                
            })
        })
    }
    if(closeModalEvents) {
        closeModalEvents.addEventListener('click', () => {
            if(modalEvents.classList.contains('modal--open')) {
                modalEvents.classList.remove('modal--open')
            }
        })
    }
}