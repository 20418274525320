import {isScrollIntoView} from '../functions'

const ourDonorsContent = document.querySelector('.donors__main-about');
const ourDonorsImage = document.querySelector('.donors__main-image');

const ourDonorsLogo = document.querySelector('.logo--donors')

const ourDonorsTopSvg = document.querySelector('.donors__top-svg');
const ourDonorsBottomSvg = document.querySelector('.donors__bottom-svg');

document.addEventListener('DOMContentLoaded', () => {
    if(ourDonorsContent) {
        setTimeout(() => ourDonorsContent.classList.add('enter'), 500);
    }

    if(ourDonorsImage) {
        setTimeout(() => ourDonorsImage.classList.add('enter'), 1000);
        setTimeout(() => ourDonorsImage.classList.add('enter-text'), 1500);
    }

    window.addEventListener('scroll', () => {
        if(ourDonorsLogo) {
            if(isScrollIntoView(ourDonorsLogo)) {
                try {
                    setTimeout(() => {
                        ourDonorsLogo.classList.add('logo--donors-triangle')
                    }, 500)
    
                    setTimeout(() => {
                        ourDonorsLogo.classList.add('logo--donors-rect')
                    }, 1000)
    
                    setTimeout(() => {
                        ourDonorsLogo.classList.add('logo--donors-animate')
                    }, 1500)
    
                } catch(err) {
                    console.error(err);
                }
            }
        }

        if(isScrollIntoView(ourDonorsTopSvg)) {
            setTimeout(() => ourDonorsTopSvg.classList.add('enter'), 1000);
        }

        if(isScrollIntoView(ourDonorsBottomSvg)) {
            setTimeout(() => ourDonorsBottomSvg.classList.add('enter'), 1000);
        }
    })
    
})