const toggleSearchBtn = document.querySelector(".btn__header--search");
const searchPopup = document.querySelector(".search");
const headerElement = document.querySelector(".header");

try {
    if (toggleSearchBtn) {
        toggleSearchBtn.addEventListener("click", (e) => {
            if (
                toggleSearchBtn.classList.contains("btn__header--search-open")
            ) {
                toggleSearchBtn.classList.remove("btn__header--search-open");
                searchPopup.classList.remove("search--open");
                headerElement.classList.remove("header--search-open");
                document.querySelector(".header__menu").style = "";
                document.querySelector(".btn__header--u-learn").style = "";
                document.querySelector(".btn__header--lang").style = "";
                document.querySelector(".btn__header--toggle").style = "";
                setTimeout(() => {
                    document.querySelector("html").style.overflowY = "auto";
                    document.querySelector("body").style.marginRight = "0";
                }, 500);
            } else {
                toggleSearchBtn.classList.add("btn__header--search-open");
                searchPopup.classList.add("search--open");
                headerElement.classList.add("header--search-open");
                document.querySelector(".header__menu").style =
                    "opacity: 0; visibility: hidden;";
                document.querySelector(".btn__header--u-learn").style =
                    "opacity: 0; visibility: hidden;";
                document.querySelector(".btn__header--lang").style =
                    "opacity: 0; visibility: hidden;";
                document.querySelector(".btn__header--toggle").style =
                    "opacity: 0; visibility: hidden;";
                setTimeout(() => {
                    document.querySelector("html").style.overflowY = "hidden";
                    document.querySelector("body").style.marginRight = "16px";
                }, 500);
            }
        });
    }
} catch (err) {
    console.error(err);
}
