<template>
    <main class="news-page">
        <div class="container">
            <div>
                <div class="news__top">
                    <div
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        data-aos-duration="500"
                        class="breadcrumbs"
                    >
                        <a :href="`/${lang}`">{{ transl.home.title }}</a>
                        <span class="line">—</span>
                        <span>{{ page.title }}</span>
                    </div>

                    <div
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        data-aos-duration="500"
                        class="section-title section-title--news"
                    >
                        {{ page.title }}
                    </div>

                    <button
                        class="btn btn__toggle-filter btn__toggle-filter--news"
                    >
                        {{ transl.news.search_in_news }}
                        <svg
                            class="search-icon"
                            viewBox="0 0 512 512"
                            enable-background="new 0 0 512 512"
                        >
                            <g>
                                <path
                                    d="M495,466.2L377.2,348.4c29.2-35.6,46.8-81.2,46.8-130.9C424,103.5,331.5,11,217.5,11C103.4,11,11,103.5,11,217.5   S103.4,424,217.5,424c49.7,0,95.2-17.5,130.8-46.7L466.1,495c8,8,20.9,8,28.9,0C503,487.1,503,474.1,495,466.2z M217.5,382.9   C126.2,382.9,52,308.7,52,217.5S126.2,52,217.5,52C308.7,52,383,126.3,383,217.5S308.7,382.9,217.5,382.9z"
                                />
                            </g>
                        </svg>
                        <svg
                            class="search-close"
                            width="512"
                            height="512"
                            x="0"
                            y="0"
                            viewBox="0 0 512 512"
                        >
                            <g>
                                <g>
                                    <path
                                        d="M510.132,489.755c-0.476-0.556-0.995-1.075-1.552-1.552L276.9,256.382l231.822-231.68c5.577-5.577,5.577-14.619,0-20.196    c-5.577-5.577-14.619-5.577-20.196,0l-231.68,231.822L25.167,4.506c-5.577-5.577-14.619-5.577-20.196,0s-5.577,14.619,0,20.196    l231.822,231.68L4.972,488.062c-5.966,5.109-6.661,14.087-1.552,20.053c5.109,5.966,14.087,6.661,20.053,1.552    c0.556-0.476,1.075-0.995,1.552-1.552l231.822-231.68l231.68,231.822c5.109,5.966,14.087,6.661,20.053,1.552    C514.546,504.699,515.241,495.721,510.132,489.755z"
                                        fill="#000000"
                                        data-original="#000000"
                                        style="
                                            block-size: auto;
                                            d: path(
                                                'M 510.132 489.755 C 509.656 489.199 509.137 488.68 508.58 488.203 L 276.9 256.382 L 508.722 24.702 C 514.299 19.125 514.299 10.083 508.722 4.506 C 503.145 -1.071 494.103 -1.071 488.526 4.506 L 256.846 236.328 L 25.167 4.506 C 19.59 -1.071 10.548 -1.071 4.971 4.506 S -0.605999 19.125 4.971 24.702 L 236.793 256.382 L 4.972 488.062 C -0.994 493.171 -1.689 502.149 3.42 508.115 C 8.529 514.081 17.507 514.776 23.473 509.667 C 24.029 509.191 24.548 508.672 25.025 508.115 L 256.847 276.435 L 488.527 508.257 C 493.636 514.223 502.614 514.918 508.58 509.809 C 514.546 504.699 515.241 495.721 510.132 489.755 Z'
                                            );
                                            height: auto;
                                            inline-size: auto;
                                            overflow-x: visible;
                                            overflow-y: visible;
                                            perspective-origin: 0px 0px;
                                            transform-origin: 0px 0px;
                                            width: auto;
                                        "
                                        class=""
                                    />
                                </g>
                            </g>
                        </svg>
                    </button>
                    <div
                        data-aos="fade-up"
                        data-aos-delay="1000"
                        data-aos-duration="500"
                        class="news__filter"
                    >
                        <div class="news__form">
                            <label>{{ transl.news.search_by }}</label>
                            <!--                <input class="input input__date" v-model="filter.date" type="date">-->
                            <month-picker-input
                                @change="focusMonthSelector"
                                :clearable="true"
                                :placeholder="transl.news.form.select_month"
                                :no-default="true"
                                :lang="lang"
                                :months="
                                    lang === 'uk'
                                        ? [
                                              'Січень',
                                              'Лютий',
                                              'Березень',
                                              'Квітень',
                                              'Травень',
                                              'Червень',
                                              'Липень',
                                              'Серпень',
                                              'Вересень',
                                              'Жовтень',
                                              'Листопад',
                                              'Грудень',
                                          ]
                                        : null
                                "
                                v-model="filter.date"
                            ></month-picker-input>

                            <v-select
                                @open="focuRegionSelector"
                                v-model="filter.region"
                                :placeholder="transl.news.form.region"
                                :options="
                                    transl.regions
                                        ? formatRegion(transl.regions)
                                        : []
                                "
                            ></v-select>

                            <button @click="apply" class="btn btn__apply">
                                {{ transl.news.form.apply }}
                            </button>
                            <button
                                @click="cancel"
                                type="button"
                                class="btn btn__cancel"
                            >
                                {{ transl.news.form.cancel }}
                            </button>
                        </div>
                    </div>
                </div>

                <div class="news__list">
                    <a
                        v-for="(news, index) of list.news"
                        :href="`/${lang}/news/${news.id}`"
                        :key="index"
                        data-aos="fade-up"
                        data-aos-delay="300"
                        data-aos-duration="500"
                        class="news__item"
                    >
                        <div
                            class="news__item-image"
                            :style="`background-image: url(${news.img});`"
                        ></div>
                        <div class="news__item-transparent"></div>
                        <div class="news__item-date">{{ news.date }}</div>
                        <div class="news__item-title">{{ news.title }}</div>
                        <div class="news__item-text">{{ news.short }}</div>

                        <div class="news__item-tags">
                            <div
                                v-for="(tag, index) of news.tags"
                                :key="index"
                                class="tag"
                            >
                                #{{ tag.tag }}
                            </div>
                        </div>

                        <div class="news__item-more">
                            <span>{{ transl.read_more }}</span>
                        </div>
                    </a>
                    <div class="media__loader" v-if="isLoading">
                        <img src="/images/loader.svg" alt="Завантаження" />
                    </div>
                    <div
                        class="media__no-data"
                        v-if="!isLoading && list.news.length === 0"
                    >
                        <icon class="no-data-svg" name="no-data" />
                        {{ transl.news.empty_list }}
                    </div>
                </div>

                <div
                    v-show="max_pagination_count > count"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="500"
                    class="news__btn"
                >
                    <button
                        @click="showMore"
                        type="button"
                        class="btn btn__see-more"
                    >
                        {{ transl.show_more }}
                    </button>
                </div>

                <div
                    v-if="list.tags.length > 0"
                    data-aos="fade-up"
                    data-aos-delay="300"
                    data-aos-duration="500"
                    class="news-single__tags"
                >
                    <div class="container">
                        <div class="news__tags-wrapper">
                            <div
                                :class="`news__tags-list ${
                                    isOpenTags ? 'news__tags-list--all' : ''
                                }`"
                            >
                                <a
                                    style="cursor: pointer"
                                    v-for="(tag, index) of list.tags"
                                    :key="index"
                                    href="#"
                                    @click="selectTag(tag.id)"
                                    :class="`news__tag ${
                                        tag.id == filter.tag ? 'active' : ''
                                    }`"
                                    >#{{ tag.tag }}</a
                                >
                            </div>
                        </div>
                        <div class="news-single__more">
                            <button
                                v-if="!isOpenTags"
                                class="btn btn__single-more btn__single-more--news"
                                @click="openMoreTags"
                            >
                                {{ transl.more_tags }}
                                <div class="dots">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                </div>
                            </button>
                            <button
                                v-if="isOpenTags"
                                @click="closeMoreTags"
                                class="btn btn__single-more btn__single-more--media btn__single-less"
                            >
                                {{ transl.less_tags }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import { MonthPickerInput } from "vue-month-picker";
import vSelect from "vue-select";
import scroll from "smooth-scroll";
import AOS from "aos";
export default {
    name: "Index",

    props: [
        "request_tag",
        "request_region",
        "lang",
        "regions",
        "page",
        "transl",
    ],

    components: {
        MonthPickerInput,
        vSelect,
    },

    data() {
        return {
            filter: {
                date: "",
                tag: this.request_tag,
                region: this.request_region
                    ? this.transl.regions[this.request_region]
                    : false,
            },

            list: {
                news: [],
                tags: [],
            },
            isLoading: true,
            isOpenTags: false,
            //pagination
            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
        };
    },

    mounted() {
        let filters;
        if (this.request_tag || this.request_region) {
            filters = {
                date: this.filter.date,
                tag: this.request_tag,
                region: this.request_region,
            };
        }

        this.getNewsList(filters);

        this.getTagsList();

        AOS.refresh();
    },

    methods: {
        focusMonthSelector() {
            if (document.querySelector(".month-picker-input-container")) {
                if (
                    !document
                        .querySelector(".month-picker-input-container")
                        .classList.contains("focus")
                ) {
                    document
                        .querySelector(".month-picker-input-container")
                        .classList.add("focus");
                }
            }
        },

        focuRegionSelector() {
            if (document.querySelector(".vs__dropdown-toggle")) {
                if (
                    !document
                        .querySelector(".vs__dropdown-toggle")
                        .classList.contains("focus")
                ) {
                    document
                        .querySelector(".vs__dropdown-toggle")
                        .classList.add("focus");
                }
            }
        },

        openMoreTags() {
            if (!this.isOpenTags) {
                this.isOpenTags = true;
            }
        },

        closeMoreTags() {
            if (this.isOpenTags) {
                this.isOpenTags = false;
            }
        },

        getNewsList(filters) {
            this.isLoading = true;

            axios
                .post("/api/" + this.lang + "/news/list", {
                    filters,
                })
                .then((r) => {
                    this.list.news = r.data.data;
                    this.pagination_count = this.count = r.data.count;
                    this.max_pagination_count = r.data.max_pagination_count;
                })
                .finally(() => (this.isLoading = false));
        },

        getTagsList(filters) {
            axios
                .post("/api/" + this.lang + "/tag/list", {
                    relation: "news",
                })
                .then((r) => {
                    this.list.tags = r.data;
                });
        },

        apply() {
            this.getNewsList({
                date: this.filter.date,
                tag: this.filter.tag,
                region: this.filter.region,
            });
        },

        selectTag(id) {
            if (id !== this.filter.tag) {
                this.filter.tag = id;

                let filters = {
                    date: this.filter.date,
                    region: this.filter.region,
                    tag: id,
                };
                this.getNewsList(filters);
            } else {
                this.filter.tag = false;

                let filters = {
                    date: this.filter.date,
                    region: this.filter.region,
                };
                this.getNewsList(filters);
            }

            new scroll().animateScroll(0, null, {
                speed: 1000,
                easing: "easeOutCubic",
            });
        },

        showMore() {
            let filters = {
                count: (this.count += this.pagination_count),
                date: this.filter.date,
                region: this.filter.region,
                tag: this.filter.tag,
            };
            this.getNewsList(filters);
        },

        formatRegion(regions) {
            if (!regions) {
                return;
            }
            const allRegions = [];

            for (const [key, value] of Object.entries(regions)) {
                allRegions.push({
                    label: `${value}`,
                    code: `${key}`,
                });
            }

            return allRegions;
        },

        cancel() {
            this.filter.date = "";
            this.filter.tag = false;
            this.filter.region = false;
            this.getNewsList();
        },
    },
};
</script>

<style scoped></style>
