import {isScrollIntoView, insertAfter} from '../functions'

const ourMissionContent = document.querySelector('.our-mission__main-text');
const ourMissionImage = document.querySelector('.our-mission__main-image');
const ourMissionWorkText = document.querySelector('.our-mission__work');
const ourMissionGrey = document.querySelector('.our-mission__grey');
const ourMissionWhite = document.querySelector('.our-mission__footer');

const ourMissionTopSvg = document.querySelector('.our-mission__top-svg');
const ourMissionWorkSvg = document.querySelector('.our-mission__work-svg');

const ourMissionLogo = document.querySelector('.logo--mission')

document.addEventListener('DOMContentLoaded', () => {
    if(ourMissionContent) {
        setTimeout(() => ourMissionContent.classList.add('enter'), 500);
    }

    if(ourMissionImage) {
        setTimeout(() => ourMissionImage.classList.add('enter-image'), 1000);
        setTimeout(() => ourMissionImage.classList.add('enter-text'), 1500);
    }

    if(ourMissionTopSvg) {
        setTimeout(() => ourMissionTopSvg.classList.add('enter'), 2500);
    }

    window.addEventListener('scroll', () => {
        if(ourMissionLogo) {
            if(isScrollIntoView(ourMissionLogo)) {
                try {
                    setTimeout(() => {
                        ourMissionLogo.classList.add('logo--mission-triangle')
                    }, 500)
    
                    setTimeout(() => {
                        ourMissionLogo.classList.add('logo--mission-rect')
                    }, 1000)
    
                    setTimeout(() => {
                        ourMissionLogo.classList.add('logo--mission-animate')
                    }, 1500)
    
                    if(ourMissionGrey) {
                        setTimeout(() => ourMissionGrey.classList.add('enter'), 2000)
                    }

                    if(ourMissionWhite) {
                        setTimeout(() => ourMissionWhite.classList.add('enter'), 2500)
                    }
                } catch(err) {
                    console.error(err);
                }
            }
        }

        if(isScrollIntoView(ourMissionLogo)) {
            setTimeout(() => ourMissionWorkText.classList.add('enter'), 1000);
        }

        if(isScrollIntoView(ourMissionWorkSvg)) {
            setTimeout(() => ourMissionWorkSvg.classList.add('enter'), 1000);
        }
    })
    
})

if(window.innerWidth < 992) {

    if(ourMissionGrey) {
    
        ourMissionGrey.insertAdjacentHTML('afterbegin', ourMissionContent.innerHTML);
        ourMissionContent.innerHTML = '';

        const ourMissionImageText = document.querySelector('.our-mission__main-image-text');

        insertAfter(ourMissionImageText, ourMissionGrey)
    }

}