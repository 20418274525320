
document.addEventListener('DOMContentLoaded', () => {

    const btnToggleTags = document.querySelector('.btn__single-more--news');
    const tagsList = document.querySelector('.news-single__tags');
    const labelRegionSelect = document.querySelector('label[for="region"]');
    const inputRegionSelect = document.getElementById('region');

    if(btnToggleTags) {

        try {
    
            btnToggleTags.addEventListener('click', () => {
                if(!tagsList.classList.contains('news__tags-list--all')) {
                    tagsList.classList.add('news__tags-list--all')
                    btnToggleTags.classList.add('hide');
                }
            })
                
        } catch(err) {
            console.error(err);
        }
        
    }
    
    
    if(inputRegionSelect) {
        try {
            labelRegionSelect.addEventListener('click', () => {
                inputRegionSelect.click();
            })
    
        } catch(err) {
            console.error(err);
        }
    }
    
    const newsFilter = document.querySelector('.news__filter');
    const btnToggleNewsFilter = document.querySelector('.btn__toggle-filter--news');
    
    if(btnToggleNewsFilter) {
        try {
            btnToggleNewsFilter.addEventListener('click', () => {
                if(newsFilter) {
                    if(newsFilter.classList.contains('news__filter--open')) {
                        newsFilter.classList.remove('news__filter--open');
                        btnToggleNewsFilter.classList.remove('btn__toggle-filter--show')
                    } else {
                        newsFilter.classList.add('news__filter--open')
                        btnToggleNewsFilter.classList.add('btn__toggle-filter--show')
                    }
                }
            })
        } catch(err) {
            console.error(err);
        }
    }
})
