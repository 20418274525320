import axios from 'axios';

const allRegions = document.querySelectorAll('.region');
const allOccupied = document.querySelectorAll('.occupied');
const infoRegionPopup = document.querySelector('.our-presence__info');
const infoOccupiedPopup = document.querySelector('.our-presence__occupied');
const btnCloseInfoRegionPopup = document.querySelector('.our-presence__info-close');

const regionPins = document.querySelectorAll('.pin');

// Attr

const ourPresenceInfoRegion = document.getElementById('our-presence-region');
const ourPresenceInfoMunicipalities = document.getElementById('our-presence-municipalities');
const ourPresenceInfoParticipants = document.getElementById('our-presence-participants');
const ourPresenceInfoActivities = document.getElementById('our-presence-activities');
const ourPresenceInfoPhone = document.getElementById('our-presence-phone');
const ourPresenceInfoAddress = document.getElementById('our-presence-address');
const ourPresenceInfoSocial = document.getElementById('our-presence-social');
const ourPresenceInfoMoreNews = document.getElementById('our-presence-more');

if(allRegions) {
    try {
        allRegions.forEach(region => {
            region.addEventListener('mouseover', () => {
                if(region.dataset.region === 'kyiv') {
                    allRegions.forEach(r => {
                        if(r.dataset.region === 'kyiv') {
                            r.classList.add('hover');
                        }
                    })
                }
                else {
                    region.classList.add('hover')
                }
                regionPins.forEach(p => {
                    if(p.dataset.region === region.dataset.region) {
                        p.classList.add('hover');
                    }
                })
            })
            region.addEventListener('mouseout', () => {
                if(region.dataset.region === 'kyiv') {
                    allRegions.forEach(r => {
                        if(r.dataset.region === 'kyiv') {
                            r.classList.remove('hover');
                            regionPins.forEach(p => {
                                if(p.dataset.region === 'kyiv') {
                                    p.classList.remove('hover');
                                }
                            })
                        }
                    })
                }
                else {
                    region.classList.remove('hover');
                    regionPins.forEach(p => {
                        p.classList.remove('hover');
                    })
                }
            })
            region.addEventListener('click', () => {
                clearAllRegions();
                if(region.dataset.region === 'kyiv') {
                    allRegions.forEach(r => {
                        if(r.dataset.region === 'kyiv') {
                            r.classList.add('hover-cliked');
                            regionPins.forEach(p => {
                                if(p.dataset.region === r.dataset.region) {
                                    p.classList.add('hover-cliked');
                                }
                            })
                        }
                    })
                } else {
                    region.classList.add('hover-cliked');
                    regionPins.forEach(p => {
                        if(p.dataset.region === region.dataset.region) {
                            p.classList.add('hover-cliked');
                        }
                    })
                }
                getInfoAboutRegion(region.dataset.region);
                const coord = region.getBoundingClientRect();
                if(window.innerWidth > 768) {
                    infoRegionPopup.style =  `top: ${50}%; left: ${50}%; transform: translateX(-50%);`
                } else {
                    infoRegionPopup.style =  `top: auto; left: ${50}%; transform: translateX(-50%); bottom: 20px`
                }
                if(infoRegionPopup.classList.contains('hide')) {
                    infoRegionPopup.classList.remove('hide');
                }
            })
        })
    } catch(err) {
        console.error(err);
    }
}

if(btnCloseInfoRegionPopup) {
    btnCloseInfoRegionPopup.addEventListener('click', () => {
        if(!infoRegionPopup.classList.contains('hide')) {
            infoRegionPopup.classList.add('hide');
            clearAllRegions();
        }
    })
}

if(regionPins) {
    regionPins.forEach(pin => {
        const dataRegion = pin.dataset.region;

        pin.addEventListener('mouseover', () => {
            allRegions.forEach(r => {
                if(r.dataset.region === dataRegion) {
                    r.classList.add('hover');
                }
            })
        })
        pin.addEventListener('mouseout', () => {
            allRegions.forEach(p => {
                if(p.dataset.region === dataRegion) {
                    p.classList.remove('hover');
                }
            })
        })

        pin.addEventListener('click', () => {
            clearAllRegions();
            // if(pin.classList.contains('eu-office')) {
            //     clearAllRegions();
            //     hideAllPopups();
            //     return
            // }
            if(pin.classList.contains('occupied')) {
                return
            }
            pin.classList.add('hover-cliked')
            getInfoAboutRegion(pin.dataset.region);
            showRegionInformation();
        })

    })
}


const hideAllPopups = () => {
    infoRegionPopup.classList.add('hide');
    infoRegionPopup.style = '';
    infoOccupiedPopup.classList.add('hide');
    infoOccupiedPopup.style = '';
    clearAllRegions();
}


const clearAllRegions = () => {
    try {
        // allRegions.forEach(r => {
        //     r.classList.remove('hover-cliked');
        // })
        regionPins.forEach(p => {
            p.classList.remove('hover-cliked');
            infoRegionPopup.style = '';
        })
    } catch(err) {
        console.error(err);
    }
}

if(allOccupied) {
    allOccupied.forEach(o => {
        o.addEventListener('mouseover', () => {
            clearAllRegions();
            hideAllPopups();
            showOccupiedInformation(o);
        })
        o.addEventListener('mouseout', () => {
            infoOccupiedPopup.classList.add('hide')
        })
    })
}

const showRegionInformation = (region) => {
    if(window.innerWidth > 768) {
        infoRegionPopup.style =  `top: ${50}%; left: ${50}%; transform: translateX(-50%);`
    } else {
        infoRegionPopup.style =  `top: auto; left: ${49}%; transform: translateX(-50%); bottom: 20px`
    }


    if(infoRegionPopup.classList.contains('hide')) {
        infoRegionPopup.classList.remove('hide');
    }
}

const showOccupiedInformation = (region) => {
    const coord = region.getBoundingClientRect();
    infoOccupiedPopup.style = `top: ${50}%; left: ${50}%; transform: translateX(-50%);`
    if(infoOccupiedPopup.classList.contains('hide')) {
        infoOccupiedPopup.classList.remove('hide');
    }
}

const getInfoAboutRegion = async (region) => {
    if(region) {

        let requestURL;

        if(document.documentElement.lang === 'en') {
            requestURL = `/api/en/region/${region}`
        } else {
            requestURL = `/api/region/${region}`
        }

        await axios.post(requestURL, {
            headers: {
                'Authorization': 'Basic dWxlYWQ6a2hHVlhFRmpBUQ=='
            }
        })
            .then(res => {
                if(res.status === 404) {
                    console.error('Response error');
                    return
                }
                if(region === 'eu-office') {
                    document.querySelector('.our-presence__info-subheader').style = 'display: none';
                    document.querySelector('.our-presence__info-header').classList.add('our-presence__info-header--eu');
                } else {
                    document.querySelector('.our-presence__info-header').classList.remove('our-presence__info-header--eu');
                    document.querySelector('.our-presence__info-subheader').style = 'display: flex';
                }
                const {address, consultations, participants, events, name, facebook, instagram, linkedin, twitter, news_href, phone, additional_phone } = res.data;
                ourPresenceInfoRegion.innerHTML = name;
                ourPresenceInfoAddress.innerHTML = address;
                ourPresenceInfoParticipants.innerHTML = participants;
                ourPresenceInfoActivities.innerHTML = events;
                ourPresenceInfoMunicipalities.innerHTML = consultations;
                ourPresenceInfoSocial.innerHTML = "";
                ourPresenceInfoMoreNews.innerHTML = "";
                ourPresenceInfoPhone.innerHTML = "";
                if(phone) {
                    ourPresenceInfoPhone.insertAdjacentHTML('beforeend', `
                        <a href="tel:${phone.replaceAll('-', '')}" target="_blank" class="item">
                            <svg x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;">
                                <path class="st0" d="M0.41,6.36c-0.26-0.72-0.46-1.45-0.4-2.22c0.04-0.48,0.22-0.89,0.57-1.22c0.38-0.36,0.74-0.74,1.11-1.1
                                    c0.49-0.48,1.1-0.48,1.59,0c0.3,0.3,0.6,0.6,0.9,0.9C4.47,3,4.76,3.29,5.05,3.59c0.51,0.51,0.51,1.11,0,1.63
                                    C4.69,5.58,4.32,5.95,3.95,6.3C3.85,6.4,3.85,6.48,3.9,6.6c0.24,0.58,0.6,1.11,0.99,1.59c0.8,0.98,1.7,1.85,2.78,2.52
                                    c0.23,0.14,0.48,0.25,0.72,0.38c0.12,0.07,0.21,0.05,0.31-0.06c0.36-0.37,0.73-0.74,1.11-1.11c0.49-0.48,1.1-0.48,1.58,0
                                    c0.6,0.59,1.19,1.18,1.78,1.78c0.5,0.5,0.49,1.11-0.01,1.61c-0.34,0.34-0.69,0.66-1.01,1.02c-0.46,0.52-1.05,0.69-1.71,0.65
                                    c-0.97-0.05-1.86-0.37-2.72-0.79c-1.91-0.93-3.55-2.22-4.91-3.84C1.79,9.15,0.96,7.84,0.41,6.36z M15,7.45C15,3.34,11.66,0,7.55,0
                                    v1.42c3.33,0,6.03,2.71,6.03,6.03H15L15,7.45z M10.92,7.45h1.42c0-2.64-2.15-4.79-4.79-4.79v1.42c0.9,0,1.75,0.35,2.38,0.99
                                    C10.57,5.7,10.92,6.55,10.92,7.45z"/>
                            </svg>
                            ${phone}
                        </a>
                    `);
                }
                if(additional_phone) {
                    ourPresenceInfoPhone.insertAdjacentHTML('beforeend', `
                        <a href="tel:${additional_phone.split()}" target="_blank" class="item">
                            <svg x="0px" y="0px" viewBox="0 0 15 15" style="enable-background:new 0 0 15 15;">
                                <path class="st0" d="M0.41,6.36c-0.26-0.72-0.46-1.45-0.4-2.22c0.04-0.48,0.22-0.89,0.57-1.22c0.38-0.36,0.74-0.74,1.11-1.1
                                    c0.49-0.48,1.1-0.48,1.59,0c0.3,0.3,0.6,0.6,0.9,0.9C4.47,3,4.76,3.29,5.05,3.59c0.51,0.51,0.51,1.11,0,1.63
                                    C4.69,5.58,4.32,5.95,3.95,6.3C3.85,6.4,3.85,6.48,3.9,6.6c0.24,0.58,0.6,1.11,0.99,1.59c0.8,0.98,1.7,1.85,2.78,2.52
                                    c0.23,0.14,0.48,0.25,0.72,0.38c0.12,0.07,0.21,0.05,0.31-0.06c0.36-0.37,0.73-0.74,1.11-1.11c0.49-0.48,1.1-0.48,1.58,0
                                    c0.6,0.59,1.19,1.18,1.78,1.78c0.5,0.5,0.49,1.11-0.01,1.61c-0.34,0.34-0.69,0.66-1.01,1.02c-0.46,0.52-1.05,0.69-1.71,0.65
                                    c-0.97-0.05-1.86-0.37-2.72-0.79c-1.91-0.93-3.55-2.22-4.91-3.84C1.79,9.15,0.96,7.84,0.41,6.36z M15,7.45C15,3.34,11.66,0,7.55,0
                                    v1.42c3.33,0,6.03,2.71,6.03,6.03H15L15,7.45z M10.92,7.45h1.42c0-2.64-2.15-4.79-4.79-4.79v1.42c0.9,0,1.75,0.35,2.38,0.99
                                    C10.57,5.7,10.92,6.55,10.92,7.45z"/>
                            </svg>
                            ${additional_phone}
                        </a>
                    `);
                }
                if(facebook) {
                    ourPresenceInfoSocial.insertAdjacentHTML('beforeend', `
                        <a href="${facebook}" target="_blank" class="item">
                            <svg id="facebook" x="0px" y="0px" viewBox="0 0 60.734 60.733" style="enable-background:new 0 0 60.734 60.733;">
                                <g>
                                    <path d="M57.378,0.001H3.352C1.502,0.001,0,1.5,0,3.353v54.026c0,1.853,1.502,3.354,3.352,3.354h29.086V37.214h-7.914v-9.167h7.914
                                        v-6.76c0-7.843,4.789-12.116,11.787-12.116c3.355,0,6.232,0.251,7.071,0.36v8.198l-4.854,0.002c-3.805,0-4.539,1.809-4.539,4.462
                                        v5.851h9.078l-1.187,9.166h-7.892v23.52h15.475c1.852,0,3.355-1.503,3.355-3.351V3.351C60.731,1.5,59.23,0.001,57.378,0.001z"/>
                                </g>
                            </svg>
                            ${document.documentElement.lang === 'en' ? 'Visit us on Facebook' : 'Відвідайте нас на Facebook'}
                        </a>
                    `)
                }
                if(twitter) {
                    ourPresenceInfoSocial.insertAdjacentHTML('beforeend',`
                        <a href="${twitter}" target="_blank" class="item">
                            <svg viewBox="0 0 512 512">
                                <path d="m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-102.1875 199.601562c.113281 2.519532.167969 5.050782.167969 7.59375 0 77.644532-59.101563 167.179688-167.183594 167.183594h.003906-.003906c-33.183594 0-64.0625-9.726562-90.066406-26.394531 4.597656.542969 9.277343.8125 14.015624.8125 27.53125 0 52.867188-9.390625 72.980469-25.152344-25.722656-.476562-47.410156-17.464843-54.894531-40.8125 3.582031.6875 7.265625 1.0625 11.042969 1.0625 5.363281 0 10.558593-.722656 15.496093-2.070312-26.886718-5.382813-47.140624-29.144531-47.140624-57.597657 0-.265624 0-.503906.007812-.75 7.917969 4.402344 16.972656 7.050782 26.613281 7.347657-15.777343-10.527344-26.148437-28.523438-26.148437-48.910157 0-10.765624 2.910156-20.851562 7.957031-29.535156 28.976563 35.554688 72.28125 58.9375 121.117187 61.394532-1.007812-4.304688-1.527343-8.789063-1.527343-13.398438 0-32.4375 26.316406-58.753906 58.765625-58.753906 16.902344 0 32.167968 7.144531 42.890625 18.566406 13.386719-2.640625 25.957031-7.53125 37.3125-14.261719-4.394531 13.714844-13.707031 25.222657-25.839844 32.5 11.886719-1.421875 23.214844-4.574219 33.742187-9.253906-7.863281 11.785156-17.835937 22.136719-29.308593 30.429687zm0 0"/>
                            </svg>
                            Twitter
                        </a>
                    `)
                }
                if(linkedin) {
                    ourPresenceInfoSocial.insertAdjacentHTML('beforeend',`
                        <a href="${linkedin}" target="_blank" class="item">
                            <svg viewBox="0 0 512 512">
                                <path d="m475.074219 0h-438.148438c-20.394531 0-36.925781 16.53125-36.925781 36.925781v438.148438c0 20.394531 16.53125 36.925781 36.925781 36.925781h438.148438c20.394531 0 36.925781-16.53125 36.925781-36.925781v-438.148438c0-20.394531-16.53125-36.925781-36.925781-36.925781zm-293.464844 387h-62.347656v-187.574219h62.347656zm-31.171875-213.1875h-.40625c-20.921875 0-34.453125-14.402344-34.453125-32.402344 0-18.40625 13.945313-32.410156 35.273437-32.410156 21.328126 0 34.453126 14.003906 34.859376 32.410156 0 18-13.53125 32.402344-35.273438 32.402344zm255.984375 213.1875h-62.339844v-100.347656c0-25.21875-9.027343-42.417969-31.585937-42.417969-17.222656 0-27.480469 11.601563-31.988282 22.800781-1.648437 4.007813-2.050781 9.609375-2.050781 15.214844v104.75h-62.34375s.816407-169.976562 0-187.574219h62.34375v26.558594c8.285157-12.78125 23.109375-30.960937 56.1875-30.960937 41.019531 0 71.777344 26.808593 71.777344 84.421874zm0 0"/>
                            </svg>
                            LinkedIn
                        </a>
                    `)
                }

                if(news_href) {
                    ourPresenceInfoMoreNews.insertAdjacentHTML('beforeend',`
                        <div class="more-news">
                            <a href="${news_href}" target="_blank" class="item">
                                ${document.documentElement.lang === 'en' ? 'Latest news' : 'Свіжі новини' }
                            </a>
                        </div>
                    `)
                }

            })
            .catch(err => {
                console.error(err);
            })
    }
}

document.addEventListener('DOMContentLoaded', () => {

    try {
        if(window.innerWidth < 576) {
            clearAllRegions();
            regionPins.forEach(pin => {
                if(pin.dataset.region === 'kyiv') {
                    pin.classList.add('hover-cliked')
                    getInfoAboutRegion(pin.dataset.region);
                }
            })
            allRegions.forEach(r => {
                if(r.dataset.region === 'kyiv') {
                    r.classList.add('hover-cliked');
                    showRegionInformation(r);
                }
            })
        }
    } catch(err) {
        console.error(err);
    }
})