<style type="text/css">
    .mission-work-svg-1{fill:#FDD617;}
    .mission-work-svg-2{fill:url(#mission-work-svg-gradient);}
    .mission-work-svg-3{fill:#ECBC2D;}
    .media-svg-menu-1{fill:#FDD617;}
    .media-svg-menu-2{fill:url(#media-svg-menu-gradient);}
    .media-svg-menu-3{fill:#ECBC2D;}
</style>

<template>
    <svg x="0px" y="0px" v-if="name === 'mission-work'" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;" >    
        <rect x="36" y="36" class="mission-work-svg-1" width="36" height="36"/>
        <linearGradient id="mission-work-svg-gradient" gradientUnits="userSpaceOnUse" x1="36" y1="55.28" x2="72" y2="55.28" gradientTransform="matrix(-1 0 0 1 72 -1.28)">
            <stop  offset="0" style="stop-color:#DD940E"/>
            <stop  offset="0.9989" style="stop-color:#FCBD14"/>
        </linearGradient>
        <rect x="0" y="36" class="mission-work-svg-2" width="36" height="36"/>
        <rect x="36" y="0" class="mission-work-svg-3" width="36" height="36"/>
    </svg>
    <svg x="0px" y="0px" v-else-if="name === 'media-menu'" viewBox="0 0 72 72" style="enable-background:new 0 0 72 72;">
        <rect x="0" y="0" class="media-svg-menu-1" width="36" height="36"/>
        <linearGradient id="media-svg-menu-gradient" gradientUnits="userSpaceOnUse" x1="36" y1="55.28" x2="72" y2="55.28" gradientTransform="matrix(-4.489653e-11 1 -1 -4.489653e-11 73.28 -1.164827e-05)">
            <stop  offset="0" style="stop-color:#DD940E"/>
            <stop  offset="0.9989" style="stop-color:#FCBD14"/>
        </linearGradient>
        <rect x="0" y="36" class="media-svg-menu-2" width="36" height="36"/>
        <rect x="36" y="0" class="media-svg-menu-3" width="36" height="36"/>
    </svg>
    <svg v-else-if="name === 'download-media'" x="0px" y="0px" viewBox="0 0 15 21" style="enable-background:new 0 0 15 21;">
        <path d="M14.68,12.02l-6.07,6.11v0.65h4.47c0.55,0,1,0.45,1,1V20c0,0.55-0.45,1-1,1H1.92c-0.55,0-1-0.45-1-1v-0.22
            c0-0.55,0.45-1,1-1h4.47v-0.65l-6.07-6.11c-0.43-0.43-0.43-1.13,0-1.56c0.43-0.43,1.12-0.43,1.55,0l4.52,4.55V1c0-0.55,0.45-1,1-1
            h0.22c0.55,0,1,0.45,1,1v14l4.51-4.54c0.43-0.43,1.12-0.43,1.55,0C15.11,10.89,15.11,11.59,14.68,12.02z"/>
    </svg>
    <svg v-else-if="name === 'no-data'" x="0px" y="0px" viewBox="0 0 104.69 122.88" style="enable-background:new 0 0 104.69 122.88"><g><path d="M98.38,88.41l-9.79,10.48L98.38,88.41L98.38,88.41z M84.3,103.48l-9.79,10.48L84.3,103.48L84.3,103.48z M56.85,116.58 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H7.33c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33 c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15c1.33,1.33,2.15,3.16,2.15,5.18V72.6 c0,1.74-1.41,3.15-3.15,3.15c-1.74,0-3.15-1.41-3.15-3.15V7.33c0-0.28-0.12-0.54-0.3-0.73c-0.19-0.19-0.45-0.3-0.73-0.3H7.33 c-0.28,0-0.54,0.12-0.73,0.3S6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.3,0.73c0.19,0.19,0.44,0.3,0.73,0.3H56.85L56.85,116.58z M31.54,86.95c-1.74,0-3.16-1.43-3.16-3.19c0-1.76,1.41-3.19,3.16-3.19h20.5c1.74,0,3.16,1.43,3.16,3.19 c0,1.76-1.41,3.19-3.16,3.19H31.54L31.54,86.95z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15 c0-1.74,1.41-3.15,3.15-3.15h41.61c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z M71.52,91.55 c-1.27-1.18-1.34-3.17-0.16-4.44c1.18-1.27,3.17-1.34,4.44-0.16l10.49,9.79l9.78-10.48c1.18-1.27,3.17-1.34,4.44-0.16 c1.27,1.18,1.34,3.17,0.16,4.44l-9.79,10.48l10.48,9.78c1.27,1.18,1.34,3.17,0.16,4.44c-1.18,1.27-3.17,1.34-4.44,0.16l-10.48-9.79 l-9.78,10.48c-1.18,1.27-3.17,1.34-4.44,0.16c-1.27-1.18-1.34-3.17-0.16-4.44L82,101.34L71.52,91.55L71.52,91.55z"/></g></svg>
</template>

<script>
    export default {
        props: ['name']
    }
</script>