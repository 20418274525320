<template>
    <div>
        <div class="events__upcoming-wrapper">
            <div
                v-for="(event, index) of list.events"
                :key="index"
                data-aos="fade-up"
                data-aos-delay="900"
                data-aos-duration="700"
                class="events__upcoming-item"
            >
                <div class="title">{{ event.title }}</div>
                <div class="image">
                    <img :src="event.image" :alt="event.title" />
                </div>
                <div class="text event-modal-selector">{{ event.short }}</div>
                <div class="more">
                    <a
                        :href="`/${lang}/news/${event.id}`"
                        class="btn btn__events-page-more"
                        >{{ transl.read_more }}</a
                    >
                </div>
            </div>
            <div class="media__loader" v-if="isLoading">
                <img src="/images/loader.svg" alt="Завантаження" />
            </div>
            <div
                class="media__no-data"
                v-if="!isLoading && list.events.length === 0"
            >
                <icon class="no-data-svg" name="no-data" />
                {{ transl.media.empty }}
            </div>
        </div>
        <div
            v-show="max_pagination_count > count"
            class="events__upcoming-more"
            data-aos="fade-up"
            data-aos-delay="1000"
            data-aos-duration="500"
        >
            <button @click="showMore" class="btn btn__media-more">
                {{ transl.show_more }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",

    props: ["lang", "transl"],

    data() {
        return {
            list: {
                events: [],
            },

            //pagination
            isLoading: true,
            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
        };
    },

    mounted() {
        this.getList();
    },

    methods: {
        getList(filters) {
            this.isLoading = true;

            axios
                .post("/api/" + this.lang + "/event/list", {
                    filters,
                })
                .then((r) => {
                    this.list.events = r.data.data;
                    this.pagination_count = this.count = r.data.count;
                    this.max_pagination_count = r.data.max_pagination_count;
                })
                .finally(() => (this.isLoading = false));
        },

        showMore() {
            let filters = {
                count: (this.count += this.pagination_count),
            };

            this.getList(filters);
        },
    },
};
</script>

<style scoped></style>
