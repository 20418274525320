// Libs
const SmoothScroll = require("smooth-scroll");
const swiper = require("swiper");
const { Pagination, Mousewheel, FreeMode, Autoplay } = require("swiper");
const AOS = require("aos");
import jump from "jump.js";

require("./presence");

import { counter, isScrollIntoView, ourImpactFormatItem } from "../functions";

const scrollHome = new SmoothScroll();
// Params
const btnToDiscover = document.getElementById("btn-to-discover");
const whatsNewContent = document.querySelector(".whats-new__content");
const btnArrowsWhatsNewSlider = document.querySelectorAll(".btn__slider-arrow");
const btnBackWhatsNew = document.getElementById("btn-back");
const btnRightWhatsNewSlider = document.querySelector(
    ".btn__slider-arrow--right"
);
const whatsNewSliderItems = document.querySelectorAll(".whats-new__item");
const homePage = document.querySelector(".home-page");
const whatsNewSection = document.querySelector("#whats-new");

let whatsNewSlider, oic;

const ourImpactCount1 = document.getElementById("our-impact-count-1");
const ourImpactCount2 = document.getElementById("our-impact-count-2");
const ourImpactCount3 = document.getElementById("our-impact-count-3");
const ourImpactCount4 = document.getElementById("our-impact-count-4");
const ourImpactCount5 = document.getElementById("our-impact-count-5");
const ourImpactCount6 = document.getElementById("our-impact-count-6");
const ourImpactCount7 = document.getElementById("our-impact-count-7");
let isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

try {
    if (btnToDiscover) {
        btnToDiscover.addEventListener("click", () => {
            // whatsNewContent.classList.add('whats-new__content--discover');
            // homePage.classList.add('slider-active')
            // btnToDiscover.classList.add('hide');
            // btnBackWhatsNew.classList.add('show')
            document.querySelector("html").style.overflowY = "hidden";
            document.querySelector("body").style.marginRight = "16px";
            document.querySelector(".header").classList.add("header--hide");
            document.querySelector(".whats-new").style = "padding-top: 0";
            document.querySelector(".btn__up").classList.remove("show");
            setTimeout(() => {
                jump(whatsNewSection);
                btnToDiscover.classList.add("hide");
                whatsNewContent.classList.add("whats-new__content--discover");
                document
                    .querySelector(".whats-new__controls")
                    .classList.add("whats-new__controls--active");
            }, 500);

            setTimeout(() => {
                initWhatsSlider();
            }, 1000);
        });
    }
} catch (err) {
    console.error(err);
}

try {
    if (btnBackWhatsNew) {
        btnBackWhatsNew.addEventListener("click", () => {
            whatsNewContent.classList.remove("whats-new__content--discover");
            document
                .querySelector(".whats-new__controls")
                .classList.remove("whats-new__controls--active");
            btnToDiscover.classList.remove("hide");
            btnBackWhatsNew.classList.remove("show");
            document.querySelector("html").style.overflowY = "auto";
            document.querySelector("body").style.marginRight = "0";
            document.querySelector(".whats-new").style = "";
            document.querySelector(".header").classList.remove("header--hide");
            whatsNewSlider.destroy();

            try {
                document
                    .getElementById("btn-whats-new--prev")
                    .removeEventListener("click");

                document
                    .getElementById("btn-whats-new--next")
                    .removeEventListener("click");
            } catch (err) {
                console.error(err);
            }
        });
    }
} catch (err) {
    console.error(err);
}

window.addEventListener("load", AOS.refresh);

window.addEventListener(
    "orientationchange",
    function () {
        if (
            window.innerHeight < window.innerWidth &&
            window.innerWidth <= 1000
        ) {
            const mainHeight = document.querySelector(".main").offsetHeight;

            document.querySelector(
                ".main-video"
            ).style = `height: ${mainHeight}px`;
        }
    },
    false
);

document.addEventListener(
    "DOMContentLoaded",
    () => {
        AOS.init({
            startEvent: "load",
            once: false,
        });

        if (document.querySelector(".stories__slider")) {
            try {
                const storiesSliderPrev = document.querySelector(
                    ".stories-button-prev"
                );
                const storiesSliderNext = document.querySelector(
                    ".stories-button-next"
                );
                const storiesSlider = new swiper.Swiper(".stories__slider", {
                    modules: [Pagination],
                    speed: 500,
                    spaceBetween: 100,
                    lazy: true,
                    loop: true,
                    slidesPerView: "auto",
                    centeredSlides: true,
                    pagination: {
                        el: ".swiper-pagination",
                        clickable: true,
                        renderBullet: (index, className) => {
                            return `<span class="${className}">${
                                index + 1
                            }</span>`;
                        },
                    },
                    navigation: {
                        prevEl: ".stories-button-prev",
                        nextEl: ".stories-button-next",
                        hiddenClass: ".stories-button-hidden",
                    },
                });

                storiesSliderNext.addEventListener("click", () => {
                    storiesSlider.slideNext();
                });

                storiesSliderPrev.addEventListener("click", () => {
                    storiesSlider.slidePrev();
                });
            } catch (err) {
                console.error(err);
            }
        }

        if (window.innerWidth < 576) {
            if (btnBackWhatsNew) {
                btnBackWhatsNew.classList.remove("show");
                try {
                    setTimeout(() => {
                        initWhatsSlider();
                    }, 1500);
                } catch (err) {
                    console.error(err);
                }
            }
        }

        if (isSafari) {
            document.querySelector(".media__video").style = "margin-top: -50px";
        }

        if (
            window.innerHeight < window.innerWidth &&
            window.innerWidth <= 1000
        ) {
            const mainHeight = document.querySelector(".main").offsetHeight;

            document.querySelector(
                ".main-video"
            ).style = `height: ${mainHeight}px`;
        }

        // Media

        const videos = document.querySelectorAll("video");
        const playBtns = document.querySelectorAll(".play-video");

        try {
            if (playBtns) {
                playBtns.forEach((playBtn) => {
                    playBtn.addEventListener("click", () => {
                        if (videos) {
                            videos.forEach(async (video) => {
                                if (
                                    video.dataset.video ===
                                    playBtn.dataset.video
                                ) {
                                    await video.play();
                                    video.controls = true;
                                    playBtn.remove();
                                }
                            });
                        }
                    });
                });
            }
        } catch (err) {
            console.error(err);
        }
    },
    false
);

const ourImpactLogoWrap = document.querySelector(".our-impact__logo");
const ourImpactLogoWrapSvg = document.querySelector(".our-impact__logo svg");
const mediaSection = document.querySelector("#media");

window.addEventListener("scroll", () => {
    if (isScrollIntoView(ourImpactLogoWrapSvg)) {
        if (
            !ourImpactLogoWrap.classList.contains("our-impact__logo--triangle")
        ) {
            ourImpactLogoWrap.classList.add("our-impact__logo--triangle");
            setTimeout(() => {
                ourImpactLogoWrap.classList.add("our-impact__logo--rect");
            }, 350);

            setTimeout(() => {
                ourImpactLogoWrap.classList.add("our-impact__logo--position");
            }, 700);

            setTimeout(() => {
                document
                    .querySelector(".our-impact__content")
                    .classList.add("our-impact__content--animated");
                if (ourImpactCount1) {
                    oic = Number(ourImpactCount1.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-1",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount2) {
                    oic = Number(ourImpactCount2.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-2",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount3) {
                    oic = Number(ourImpactCount3.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-3",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount4) {
                    oic = Number(ourImpactCount4.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-4",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount5) {
                    oic = Number(ourImpactCount5.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-5",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount6) {
                    oic = Number(ourImpactCount6.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-6",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
                if (ourImpactCount7) {
                    oic = Number(ourImpactCount7.innerHTML.replace(/\s/g, ""));
                    counter(
                        "our-impact-count-7",
                        oic - 100 < 0 ? 0 : oic - 100,
                        oic,
                        1000
                    );
                }
            }, 1000);
        }
    }

    if (isScrollIntoView(document.querySelector(".section-title"))) {
        if (document.querySelector(".whats-new__slider")) {
            setTimeout(() => {
                document
                    .querySelector(".whats-new__slider")
                    .classList.remove("whats-new__slider--hide");
            }, 750);
        }
    }

    if (isScrollIntoView(document.querySelector(".section-title--media"))) {
        if (!mediaSection.classList.contains("media--logo-triangle")) {
            mediaSection.classList.add("media--logo-triangle");
            document
                .querySelector(".media__content")
                .classList.add("media__content--init");
            setTimeout(() => {
                document
                    .querySelector(".media__content")
                    .classList.add("media__content--fade");
            }, 500);
            setTimeout(() => {
                mediaSection.classList.add("media--logo-rect");
            }, 1000);
            setTimeout(() => {
                mediaSection.classList.add("media--logo-position");
            }, 2000);

            setTimeout(() => {
                document
                    .querySelector(".media__content")
                    .classList.add("media__content--moved");
            }, 2500);

            setTimeout(() => {
                document
                    .querySelector(".media__video")
                    .classList.add("media__video--fade");
            }, 3200);

            setTimeout(() => {
                document
                    .querySelector(".media__video")
                    .classList.add("media__video--bg");
            }, 4000);
        }
    }
});

// Slider whats new

const initWhatsSlider = () => {
    btnBackWhatsNew.classList.add("show");
    whatsNewSlider = new swiper.Swiper(".whats-new__slider", {
        modules: [Mousewheel, FreeMode, Pagination],
        noSwiping: false,
        grabCursor: true,
        slidesPerView: "auto",
        passiveListeners: true,
        spaceBetween: 30,
        pagination: {
            el: ".swiper-pagination",
            clickable: true,
            renderBullet: (index, className) => {
                return `<span class="${className}">${index + 1}</span>`;
            },
        },
        freeMode: {
            enabled: true,
            sticky: false,
            momentumBounce: false,
        },
        mousewheel: true,
        scrollbar: {
            el: ".swiper-scrollbar",
            draggable: true,
            dragSize: 100,
        },
        breakpoints: {
            320: {
                freeMode: {
                    enabled: false,
                },
                slidesPerView: 1,
            },
            576: {
                freeMode: {
                    enabled: true,
                    sticky: false,
                    momentumBounce: false,
                },
            },
        },
    });

    whatsNewSlider.on("touchStart", (swiper, event) => {
        if (window.innerWidth > 992) {
            event.preventDefault();
            if (!swiper.el.classList.contains("slider-toched")) {
                swiper.el.classList.add("slider-toched");
            }
        }
    });

    whatsNewSlider.on("touchEnd", (swiper, event) => {
        if (window.innerWidth > 992) {
            event.preventDefault();
            if (swiper.el.classList.contains("slider-toched")) {
                swiper.el.classList.remove("slider-toched");
            }
        }
    });

    whatsNewSlider.on(
        "mousewheel",
        (swiper, event) => {
            event.preventDefault();
        },
        { passive: false }
    );

    whatsNewSlider.on(
        "scroll",
        (swiper, event) => {
            event.preventDefault();
            const items = swiper.slides;

            if (items && window.innerWidth > 992) {
                items.forEach((item, index) => {
                    if (index % 2 === 0) {
                        item.style = `transform: translate3d(0, -${
                            35 + swiper.translate / 75
                        }px, 0)`;
                    } else {
                        item.style = `transform: translate3d(0, ${
                            35 + swiper.translate / 75
                        }px, 0)`;
                    }
                });
            }
        },
        { passive: false }
    );

    setTimeout(() => {
        whatsNewSlider.slideNext();
    }, 1000);

    try {
        document
            .getElementById("btn-whats-new--prev")
            .addEventListener("click", () => {
                whatsNewSlider.slidePrev();
            });

        document
            .getElementById("btn-whats-new--next")
            .addEventListener("click", () => {
                whatsNewSlider.slideNext();
            });
    } catch (err) {
        console.error(err);
    }
};

try {
    const ourImpactCountElementSeven =
        document.getElementById("our-impact-count-7");
    const ourImpactTextElementSeven =
        document.getElementById("our-impact-text-7");
    const ourImpactImageElementSeven =
        document.getElementById("our-impact-image-7");
    const outImpactTargetElementSeven = document.querySelector(
        ".our-impact__content-item--7"
    );

    if (document.documentElement.lang == "uk") {
        ourImpactFormatItem(
            ourImpactTextElementSeven,
            ourImpactImageElementSeven,
            ourImpactCountElementSeven,
            outImpactTargetElementSeven,
            7,
            0
        );
    } else {
        ourImpactFormatItem(
            ourImpactTextElementSeven,
            ourImpactImageElementSeven,
            ourImpactCountElementSeven,
            outImpactTargetElementSeven,
            7,
            2
        );
    }
} catch (err) {
    console.error(err);
}

try {
    const ourImpactCountElementThree =
        document.getElementById("our-impact-count-3");
    const ourImpactTextElementThree =
        document.getElementById("our-impact-text-3");
    const ourImpactImageElementThree =
        document.getElementById("our-impact-image-3");
    const outImpactTargetElementThree = document.querySelector(
        ".our-impact__content-item--3"
    );

    ourImpactFormatItem(
        ourImpactTextElementThree,
        ourImpactImageElementThree,
        ourImpactCountElementThree,
        outImpactTargetElementThree,
        3,
        document.documentElement.lang === "en" ? 3 : 2
    );
} catch (err) {
    console.error(err);
}

try {
    const ourImpactCountElementSeven =
        document.getElementById("our-impact-count-7");
    const ourImpactTextElementSeven =
        document.getElementById("our-impact-text-7");
    const ourImpactImageElementSeven =
        document.getElementById("our-impact-image-7");
    const outImpactTargetElementSeven = document.querySelector(
        ".our-impact__content-item--7"
    );

    ourImpactFormatItem(
        ourImpactTextElementSeven,
        ourImpactImageElementSeven,
        ourImpactCountElementSeven,
        outImpactTargetElementSeven,
        7
    );
} catch (err) {
    console.error(err);
}

const ourActivitiesHomeSlider = document.querySelector(
    ".our-activities__right-slider"
);
const ourEventsHomeSlider = document.querySelector(
    ".upcoming-events__right-slider"
);

if (ourActivitiesHomeSlider) {
    try {
        let initOurActivitiesSlider = new swiper.Swiper(
            ourActivitiesHomeSlider,
            {
                modules: [Pagination, Autoplay],
                autoplay: {
                    delay: 3000,
                },
                slidesPerView: 1,
                spaceBetween: 0,
                autoplay: true,
                noSwiping: false,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span class="${className}"></span>`;
                    },
                },
            }
        );
    } catch (err) {
        console.error(err);
    }
}

if (ourEventsHomeSlider) {
    try {
        let initOurEventsSlider = new swiper.Swiper(ourEventsHomeSlider, {
            modules: [Pagination],
            slidesPerView: 1,
            spaceBetween: 0,
            noSwiping: false,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: (index, className) => {
                    return `<span class="${className}"></span>`;
                },
            },
        });
    } catch (err) {
        console.error(err);
    }
}
