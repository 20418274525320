import {insertAfter} from '../functions';

document.addEventListener('DOMContentLoaded', () => {
    if(window.innerWidth < 769) {
        const allItemsTeam = document.querySelectorAll('.team__content-item');

        if(allItemsTeam.length) {
            allItemsTeam.forEach(item => {
                const photoDiv = item.querySelector('.team__content-item-photo');
                const nameDiv = item.querySelector('.team__content-item-author');

                if(photoDiv && nameDiv) {
                    insertAfter(nameDiv, photoDiv);
                }

            })
        }

    }
}, false)