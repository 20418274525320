<template>
    <div>
        <div class="team__content">
            <div class="section-title team-title bold">
                {{ page.subtitle ? page.subtitle : "Undefined" }}
            </div>
            <div
                v-for="(team, index) of list.team"
                :key="team"
                data-aos="fade-up"
                data-aos-offset="100"
                data-aos-duration="500"
                class="team__content-item"
            >
                <div class="team__content-item-wrap">
                    <div class="team__content-item-photo">
                        <img :src="team.image" :alt="team.title" />
                    </div>
                    <div class="team__content-item-descr">
                        <div class="title bold">{{ team.title }}</div>
                        <div
                            class="text text--grey regular"
                            v-html="team.description"
                        ></div>
                    </div>
                </div>
            </div>
        </div>

        <div v-show="max_pagination_count > count" class="news__btn">
            <button @click="showMore" type="button" class="btn btn__see-more">
                {{ transl.show_more }}
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index",

    props: ["page", "lang", "transl"],

    data() {
        return {
            list: {
                team: [],
            },

            //pagination
            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
        };
    },

    mounted() {
        this.getTeamList();
    },

    methods: {
        getTeamList(filters) {
            axios
                .post("/api/" + this.lang + "/team/list", {
                    filters,
                })
                .then((r) => {
                    this.list.team = r.data.data;
                    this.pagination_count = this.count = r.data.count;
                    this.max_pagination_count = r.data.max_pagination_count;
                });
        },

        showMore() {
            let filters = {
                count: (this.count += this.pagination_count),
            };

            this.getTeamList(filters);
        },
    },
};
</script>

<style scoped></style>
