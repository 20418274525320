document.addEventListener('DOMContentLoaded', () => {
    const newsSingleSlider = document.querySelector('.news-single__slider');
    const btnShowMoreTags = document.querySelector('.btn__single-more');
    const btnLessMoreTags = document.querySelector('.btn__single-less--news');
    const tagsListSingle = document.querySelector('.news__tags-list');
    const swiper = require('swiper');
    const {Pagination, Mousewheel, FreeMode} = require('swiper');

    let newsSlider, newsOtherSlider;

    const initOtherNewsSlider = () => {
        newsOtherSlider = new swiper.Swiper('.news-other__slider', {
            noSwiping: false,
            grabCursor: true,
            slidesPerView: 3,
            passiveListeners: true,
            spaceBetween: 30,
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next',
            },
            breakpoints: {
                320: {
                    freeMode: {
                        enabled: false
                    },
                    slidesPerView: 1
                },
                576: {
                    freeMode: {
                        enabled: true,
                        sticky: false,
                        momentumBounce: false
                    },
                    slidesPerView: 2
                },
                1000: {
                    slidesPerView: 3
                }
            }
        })

        newsOtherSlider.on('touchStart', (swiper, event) => {
            if(!event) {
                return
            }
            if((window.innerWidth > 992)) {
                event.preventDefault();
                if(!swiper.el.classList.contains('slider-toched')) {
                    swiper.el.classList.add('slider-toched')
                } 
            }
        })

        newsOtherSlider.on('touchEnd', (swiper, event) => {
            if(!event) {
                return
            }
            if((window.innerWidth > 992)) {
                event.preventDefault();
                if(swiper.el.classList.contains('slider-toched')) {
                    swiper.el.classList.remove('slider-toched')
                }
            }
        })

        newsOtherSlider.on('mousewheel', (swiper, event) => {
            if(!event) {
                return
            }
            event.preventDefault();
        }, { passive: false });

        newsOtherSlider.on('scroll', (swiper, event) => {
            if(!event) {
                return
            }
            event.preventDefault();
            const items = swiper.slides;

            if(items && (window.innerWidth > 992)) {
                items.forEach((item, index) => {
                    if(index % 2 === 0) {
                        item.style = `transform: translate3d(0, -${35 + (swiper.translate / 75)}px, 0)`
                    } else {
                        item.style = `transform: translate3d(0, ${35 + (swiper.translate / 75)}px, 0)`
                    }
                })
            }
            
        }, { passive: false });

        try {
            document.querySelector('.news-other__slider-button-prev').addEventListener('click', () => {
                newsOtherSlider.slidePrev();
            })

            document.querySelector('.news-other__slider-button-next').addEventListener('click', () => {
                newsOtherSlider.slideNext();
            })
        } catch(err) {
            console.error(err);
        }


    }

    if(document.querySelector('.news-other__slider')) {
        initOtherNewsSlider();
    }

    if(newsSingleSlider) {
        try {

            newsSlider = new swiper.Swiper(newsSingleSlider, {
                modules: [Pagination, FreeMode],
                speed: 500,
                spaceBetween: 100,
                lazy: true,
                slidesPerView: 'auto',
                loop: true,
                centeredSlides: true,
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<span class="${className}">${index + 1}</span>`
                    }
                },
                freeMode: {
                    enabled: true,
                    sticky: false,
                    momentumBounce: false,
                },
                navigation: {
                    prevEl: '.stories-button-prev',
                    nextEl: '.stories-button-next',
                    hiddenClass: '.stories-button-hidden'
                }
            })

        } catch(err) {
            console.error(err);
        }

        try {
            document.querySelector('.news-single__slider-button-prev').addEventListener('click', () => {
                newsSlider.slidePrev();
            })

            document.querySelector('.news-single__slider-button-next').addEventListener('click', () => {
                newsSlider.slideNext();
            })
        } catch(err) {
            console.error(err);
        }
    }

    if(btnShowMoreTags) {
        try {
            btnShowMoreTags.addEventListener('click', () => {
                if(tagsListSingle) {
                    tagsListSingle.classList.add('news__tags-list--show');
                    btnShowMoreTags.classList.add('hide');
                    btnLessMoreTags.classList.remove('hide');
                }
            })
        } catch(err) {
            console.error(err);
        }
    }

    if(btnLessMoreTags) {
        try {
            btnLessMoreTags.addEventListener('click', () => {
                if(tagsListSingle) {
                    tagsListSingle.classList.remove('news__tags-list--show');
                    btnShowMoreTags.classList.remove('hide');
                    btnLessMoreTags.classList.add('hide');
                }
            })
        } catch(err) {
            console.error(err);
        }
    }

})