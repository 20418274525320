<template>
    <div>

        <div class="search-results__list">
            <div v-for="(item, index) of result" :key="index" class="search-results__item" data-aos="fade-up" data-aos-delay="500" data-aos-duration="700">
                <a :href="item.link" class="title"><span>{{ index+1 }}.</span> {{ item.title }}</a>
                <div class="text" v-html="item.short"></div>
            </div>
            <div v-if="result.length === 0">
                {{  transl.search.empty_list }}
            </div>
        </div>
        <div v-show="max_pagination_count > count" class="media-page__more" data-aos="fade-up" data-aos-delay="1000" data-aos-duration="500">
            <button @click="showMore" class="btn btn__media-more">{{  transl.show_more }}</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "Index.vue",

    props: ['lang', 'search', 'transl'],

    data() {
        return {
            result: [],

            pagination_count: Number,
            count: Number,
            max_pagination_count: Number,
            isLoading: true,
        }
    },

    mounted() {
        if(this.search && this.search.length > 0) {
            this.getResultList({search: this.search});
        }
    },

    methods: {
        getResultList(filters) {
            this.isLoading = true;

            axios.post('/api/'+this.lang+'/search/list', {
                filters
            }).then(r=>{
                this.result = r.data.data;
                this.pagination_count = this.count = r.data.count;
                this.max_pagination_count = r.data.max_pagination_count;
            }).finally(() => this.isLoading = false)
        },

        showMore() {
            let filters = {
                search: this.search,
                count: this.count+=this.pagination_count,
            };
            this.getResultList(filters);
        },
    }
}
</script>

<style scoped>

</style>
