const swiper = require('swiper');
const {Pagination, Mousewheel, FreeMode} = require('swiper');
import {isScrollIntoView, changeCounterSlider} from '../functions'

const nodeStoryTopSlider = document.querySelector('.story-top__slider');
const nodeStoryBottomSlider = document.querySelector('.story-bottom__slider');

let storyTopSlider, storyBottomSlider;

if(nodeStoryTopSlider) {
    try {

        storyTopSlider = new swiper.Swiper(nodeStoryTopSlider, {
            modules: [FreeMode],
            speed: 500,
            spaceBetween: 100,
            lazy: true,
            slidesPerView: 'auto',
            loop: true,
            centeredSlides: true,
            freeMode: {
                enabled: true,
                sticky: false,
                momentumBounce: false,
            },
            navigation: {
                prevEl: '.stories-button-prev',
                nextEl: '.stories-button-next',
                hiddenClass: '.stories-button-hidden'
            }
        })

        changeCounterSlider(storyTopSlider, document.querySelector('.story-top__slider-pagination'), 1, storyTopSlider.loopedSlides);

        try {
            document.querySelector('.story-top__slider-button-prev').addEventListener('click', () => {
                storyTopSlider.slidePrev();
                changeCounterSlider(storyTopSlider, document.querySelector('.story-top__slider-pagination'), storyTopSlider.realIndex + 1, storyTopSlider.loopedSlides);
            })
    
            document.querySelector('.story-top__slider-button-next').addEventListener('click', () => {
                storyTopSlider.slideNext();
                changeCounterSlider(storyTopSlider, document.querySelector('.story-top__slider-pagination'), storyTopSlider.realIndex + 1, storyTopSlider.loopedSlides);
            })
        } catch(err) {
            console.error(err);
        }

    } catch(err) {
        console.error(err);
    }
}

if(nodeStoryBottomSlider) {
    try {

        storyBottomSlider = new swiper.Swiper(nodeStoryBottomSlider, {
            modules: [FreeMode],
            speed: 500,
            spaceBetween: 100,
            lazy: true,
            slidesPerView: 'auto',
            loop: true,
            centeredSlides: true,
            freeMode: {
                enabled: true,
                sticky: false,
                momentumBounce: false,
            },
            navigation: {
                prevEl: '.stories-button-prev',
                nextEl: '.stories-button-next',
                hiddenClass: '.stories-button-hidden'
            }
        })

        changeCounterSlider(storyBottomSlider, document.querySelector('.story-bottom__slider-pagination'), 1, storyBottomSlider.loopedSlides);

        try {
            document.querySelector('.story-bottom__slider-button-prev').addEventListener('click', () => {
                storyBottomSlider.slidePrev();
                changeCounterSlider(storyBottomSlider, document.querySelector('.story-bottom__slider-pagination'), storyBottomSlider.realIndex + 1, storyBottomSlider.loopedSlides);
            })
    
            document.querySelector('.story-bottom__slider-button-next').addEventListener('click', () => {
                storyBottomSlider.slideNext();
                changeCounterSlider(storyBottomSlider, document.querySelector('.story-bottom__slider-pagination'), storyBottomSlider.realIndex + 1, storyBottomSlider.loopedSlides);
            })
        } catch(err) {
            console.error(err);
        }

    } catch(err) {
        console.error(err);
    }
}

const storyMainVideo = document.querySelector('.story-main__video');

document.addEventListener('DOMContentLoaded', () => {

    if(document.querySelector('.story-page')) {
        window.scrollTo(0,0);
    }

    if(storyMainVideo) {
        setTimeout(() => {
            storyMainVideo.classList.add('story-main__video--logo-triangle')
        }, 500)
        setTimeout(() => {
            storyMainVideo.classList.add('story-main__video--logo-rect')
        }, 1500)
        setTimeout(() => {
            storyMainVideo.classList.add('story-main__video--logo-position')
        }, 2500)
        setTimeout(() => {
            storyMainVideo.classList.add('story-main__video--rectangle-animate')
        }, 3000)
        setTimeout(() => {
            storyMainVideo.classList.add('story-main__video--video-animate')
        }, 4250)
    }
})

const nodeStoryVideo = document.querySelector('.story-video__right');
const nodeStoryLogo = document.querySelector('.logo--story');

window.addEventListener('scroll', () => {
    if(nodeStoryLogo) {
        if(isScrollIntoView(nodeStoryLogo)) {
            try {

                setTimeout(() => {
                    nodeStoryVideo.classList.add('story-video__right--logo-triangle')
                }, 500)

                setTimeout(() => {
                    nodeStoryVideo.classList.add('story-video__right--logo-rect')
                }, 1000)

                setTimeout(() => {
                    nodeStoryVideo.classList.add('story-video__right--logo-animate')
                }, 1500)
        
                setTimeout(() => {
                    nodeStoryVideo.classList.add('story-video__right--rect-animate')
                }, 2000)

                setTimeout(() => {
                    nodeStoryVideo.classList.add('story-video__right--video-animate')
                }, 3000)
            } catch(err) {
                console.error(err);
            }
            
        }

        animateAllSqueres()
    }
})

const animateAllSqueres = () => {

    const allSquares = document.querySelectorAll('.story-square');

    if(allSquares.length) {
        allSquares.forEach(s => {
            if(isScrollIntoView(s)) {
                setTimeout(() => {
                    s.classList.add('story-square--animate')
                }, 600)
            }
        })
    }
}

document.addEventListener('DOMContentLoaded', () => animateAllSqueres);
window.addEventListener('scroll', animateAllSqueres);

